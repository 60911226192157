import React, {useEffect, useState} from "react";

import './BenchmarkNew.scss';
import {Spin} from "antd";
import AnalyseList from "./components/AnalyseList";
import history from "../../history";

const BenchmarkNew = ({
  getBenchmarkForms,
  isFetching
}) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getBenchmarkForms();
    setLoader(isFetching);
  }, [])

  const handlerSetFormId = (id) => {
    history.push(`/benchmark/${id}`);
  };

  return (
    <div className={'benchmark-block'}>
      {loader
      ? <Spin size={'large'} />
      : (
        <>
           <AnalyseList handlerSetFormId={handlerSetFormId} />
        </>
        )
      }
    </div>
  )
};

export default BenchmarkNew;