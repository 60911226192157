import { connect } from 'react-redux';
import SelectSegmentationFilter from "./SelectSegmentationFilter";

const mapDispatchToProps = (dispatch) => ({
  
})

const mapStateToProps = (state) => ({

});

export default connect(
  mapDispatchToProps,
  mapStateToProps
)(SelectSegmentationFilter);