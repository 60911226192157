import actions from "../../../store/actions";
import { connect } from 'react-redux';
import ModalDrillDownGroups from "./ModalDrilldownGroups";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkDrillDownForGroups: (params) => dispatch(actions.benchmark.getBenchmarkDrillDownForGroups(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDrillDownGroups);