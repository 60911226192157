import { connect } from 'react-redux'

import Questionnaire from './Questionnaire'
import actions from "../../store/actions";

const mapStateToProps = state => ({
  user: state.user,
  app: state.app
})

const mapDispatchToProps = dispatch => ({
  getSubmissionPeriods: (params) => dispatch(actions.questionnaires.getSubmissionPeriods(params)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaire)
