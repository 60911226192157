import actions from "../../../store/actions";
import { connect } from 'react-redux';
import ModalDrilldownOrganization from "./ModalDrilldownOrganization";

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  getBenchmarkDrillDownForChildOrganizations: (params) => dispatch(actions.benchmark.getBenchmarkDrillDownForChildOrganizations(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDrilldownOrganization);