import React, {Component} from 'react'
import {Button, Row, Col, Spin} from 'antd';
import {withTranslation} from 'react-i18next'

import history from '../../history'
import {api} from '../../services'
import {Card, CustomerTable} from '../../components'

class Home extends Component {
    state = {
        fetch: false,
        questionnaires: [],
        reports: []
    }

    handlerProgressForm = (data) => {
        const formIds = data.map(form => form.id)
        if (formIds.length) {
            api.questionnaires.getProgressFormBatch(formIds)
                .then(results => {
                    let response = results.data;
                    const forms = [];

                    if (response.id)
                        response = [response];

                    response = Object.keys(response).map(function (key) {
                        return {...response[key]}
                    })

                    response.forEach((webForm, i) => {
                        const form = data.find(({ id }) => webForm.id === id)
                        const progress = webForm.progress || {count: 0, filled: 0}
                        const percent = progress.count ? Math.round(100 / progress.count_input * progress.filled) : 0

                        let status = 'open';
                        if (percent === 100)
                            status = 'completed';
                        else if (percent)
                            status = 'in_progress';

                        forms.push({ ...form, status, percent, progress })
                    })

                    this.setState(state => ({
                        questionnaires: forms
                    }))
                })
                .catch(error => console.log(error))
        }
    }

    componentDidMount() {
        const { user: { isGuest } } = this.props;

        if(isGuest)
            return;

        this.setState({fetch: true});

        Promise.all([api.reports.getFullReports(), api.questionnaires.getForms()])
            .then(results => results.map(i => i.data))
            .then(([{reports}, {forms}]) => {
                this.setState({
                    fetch: false,
                    questionnaires: forms.slice(0, 3),
                    reports: reports.slice(0, 3)
                });

                if (forms.length)
                    this.handlerProgressForm(forms.slice(0, 3));
            })
            .catch((error) => {
                console.log(error)
            });
    }

    render() {
        const {fetch, questionnaires, reports} = this.state;
        const { t } = this.props;
        const { user: { isGuest, data: { data } } } = this.props;

        return (
            <div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='page-title-box'>
                            <h4 className='page-title'>{t('welcome_back')}, {!isGuest ? data?.display_name : t('Guest')}</h4>
                        </div>
                    </div>
                </div>


                {!isGuest && (
                    <>
                        {fetch ? 
                            <div className='d-flex justify-content-center mt-5'>
                                <Spin/>
                            </div>
                            : (!!questionnaires.length && <div className='containerQuestionnaire'>
                                <div className='questionnaireTitle'>
                                    {t('latest_questionnaires')}
                                </div>
                                <Row gutter={24} type='flex' justify='start' align='top'>
                                    <Col span={24}>
                                        <CustomerTable selectedForms={questionnaires} pagination t={t}/>
                                    </Col>
                                </Row>
                                <div className='row mb-2' style={{marginBottom: '0px !important'}}>
                                    <div className='col-sm-12'>
                                        <div className='text-sm-left'>
                                            <Button shape='round' type='default'
                                                    onClick={() => history.push('/questionnaires')}>{t('view_all_questionnaires')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }

                        {!!reports.length && <div className='reportsTitle'>{t('latest_reports')}</div>}

                        {fetch ? 
                            <div className='d-flex justify-content-center mt-5'>
                                <Spin/>
                            </div>
                            : <Row gutter={24} type='flex' justify='start' align='top' className='mt-3'>
                                {reports.map(item =>
                                    <Col span={8} key={item.id}>
                                        <Card
                                            item={item}
                                            type='report'
                                            withTitle
                                            style={{background: '#f9fcfe', marginBottom: '31px'}}
                                        />
                                    </Col>
                                )}
                            </Row>
                        }

                        {!!reports.length && <div className='row mb-2'>
                            <div className='col-sm-12'>
                                <div className='text-sm-left'>
                                    <Button shape='round' type='default'
                                            onClick={() => history.push('/dashboard?type=all')}>{t('view_all_reports')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        }
                </>
                )}
            </div>
        );
    }
}

export default withTranslation()(Home)
