import "./ModalBenchmarkViewEditor.scss";

import { api } from "../../../../services";
import { Button, Checkbox, Collapse, Input, message, Popover, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

function ModalBenchmarkViewEditor(props) {
  const { 
    comparisonGroups,
    createBenchmarkView,
    formId, 
    getAllBenchmarkElements,
    loadingComparisonGroups,
    onCancel, 
    onSave, 
    organization, 
    saveBenchmarkView,
    selectedView,
    t, 
    user 
  } = props;

  const [formElements, setFormElements] = useState([]);
  const [loadingFormElements, setLoadingFormElements] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [savingView, setSavingView] = useState(false);
  const [view, setView] = useState({
    created_by: 0,
    elements: [], 
    filter_groups: [],
    filter_organizations: [],
    form_id: formId ?? 0,
    id: 0, 
    modified_by: 0,
    organization_id: organization.id, 
    sharing: { 
      organizations: [], 
      users: [] 
    }, 
    title: '' 
  });

  useEffect(() => {
    if (!!!formId)
      return;

    async function fetchData() {
      setLoadingFormElements(true);

      await getAllBenchmarkElements({ formId: formId })
        .then((response) => {
          if (response.status < 300) {
            setFormElements(response.data.values);
          }
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingFormElements(false));
    }

    fetchData();    
  }, [formId]);

  useEffect(() => {
    if (!!selectedView)
      setView(selectedView);
    else
      setView({
        created_by: user?.data?.data?.id ?? 0,
        elements: [], 
        filter_groups: [],
        filter_organizations: [],
        form_id: formId,
        id: 0,
        modified_by: user?.data?.data?.id ?? 0,
        sharing: { 
          organizations: [], 
          users: [] 
        }, 
        title: '' 
      });
  }, [selectedView]);

  useEffect(() => {
    if (!!!user?.data?.organisations)
      return;

    setOrganizations(user.data.organisations.sort((x, y) => x.title.localeCompare(y.title)));
  }, [user]);

  const handleCancel = () => {
    onCancel();
  }

  const handleFilterGroupsChange = (e) => {
    setView({
      ...view,
      filter_groups: e
    });
  }

  const handleFilterOrganizationsChange = (e) => {
    setView({
      ...view,
      filter_organizations: e
    });
  }

  const handleSave = async () => {
    if (view.title.length === 0) {
      message.error(t('title_required'));
      return;
    }

    if (view.elements.length === 0) {
      message.error(t('elements_required'));
      return;
    }

    if (!!!view.created_by && !!!user?.data?.data?.id) {
      message.error(t('unauthorized'));
      return;
    }

    setSavingView(true);

    if (!!view.id) {
      await saveBenchmarkView({view: view})
        .then ((response) => {
          if (response.status < 300)
            message.success(t('saved_view'));
        })
        .catch((e) => console.error(e))
        .finally(() => setSavingView(false));
    }
    else {
      await createBenchmarkView({view: view})
        .then ((response) => {
          if (response.status < 300)
            message.success(t('saved_view'));
        })
        .catch((e) => console.error(e))
        .finally(() => setSavingView(false));
    }

    onSave();
  }

  const handleSelectedElementChange = (element, evt) => {
    if (!evt.target.checked)
      setView({
        ...view,
        elements: view.elements.filter(x => x.indicator != element.webform_indicator)
      });
    else
      setView({
        ...view,
        elements: [
          ...view.elements,
          {
            indicator: element.webform_indicator
          }
        ]
      });
  }

  const handleShareOrganizationsChange = (e) => {
    setView({
      ...view,
      sharing: {
        ...view.sharing,
        organizations: e
      }
    });
  }

  const handleTitleChange = (e) => {
    setView({
      ...view,
      title: e.target.value
    });
  }
  
  return (
    <div className={'view-editor'}>
      <div className={'view-editor-columns'}>
        <div className={'view-editor-column'}>
          <p className={'view-editor-column-title'}>{t('available_elements')}</p>
          <div className={'view-editor-column-content'}>
            {loadingFormElements ? <Spin size={'large'}/> : (
              <div className={'view-editor-elements'}>
                {formElements.map(e => {
                  if (e.type === 'category_container') {
                    return (
                      <div className={`view-editor-category ${!!(e.webform_parent_key?.length ?? 0) ? 'view-editor-subcategory' : ''}`} key={e.webform_key}>
                        <p>{e.title}</p>
                      </div>
                    );
                  }
                  else {
                    return (
                      <div className={'view-editor-element'} key={e.webform_key}>
                        <div className={'view-editor-element-checkbox'}>
                          <Checkbox checked={view.elements.find(x => x.indicator === e.webform_indicator)} disabled={savingView} onChange={(evt) => handleSelectedElementChange(e, evt)}/>
                        </div>
                        <div className={'view-editor-element-info'}>
                          <p className={'view-editor-element-title'}>{e.title}</p>
                          <p className={'view-editor-element-type'}>{e.type}</p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
        <div className={'view-editor-column'}>
          <p className={'view-editor-column-title'}>{t('favorite_elements')}</p>
          <div className={'view-editor-column-content'}>
            {loadingFormElements ? <Spin size={'large'}/> : (
              <div className={'view-editor-elements'}>
                {!!view.elements.length && formElements.filter(x => view.elements.map(y => y.indicator).includes(x.webform_indicator)).map(e => (
                  <div className={'view-editor-element'} key={e.webform_key}>
                    <div className={'view-editor-element-info'}>
                      <p className={'view-editor-element-title'}>{e.title}</p>
                      <p className={'view-editor-element-type'}>{e.type}</p>
                    </div>
                    <div className={'view-editor-element-actions'}>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className={'view-editor-column'}>
          <p className={'view-editor-column-title'}>{t('filters')}</p>
          <div className={'view-editor-column-content'}>
            {loadingComparisonGroups ? <Spin size={'large'}/> : !!comparisonGroups && (
              <div className={'view-editor-field'}>
                <label className={'view-editor-field-label'}>{t('comparison_groups')} ({view.filter_groups.length})</label>
                <Checkbox.Group 
                  className={'view-editor-checkbox-group'}
                  disabled={savingView}
                  onChange={handleFilterGroupsChange} 
                  options={comparisonGroups.map(o => {
                    return {
                      label: o.name,
                      value: Number(o.id)
                    };
                  })}
                  value={view.filter_groups ?? []}
                />
              </div>
            )}

            <div className={'view-editor-field'}>
              <label className={'view-editor-field-label'}>{t('individual_organizations')} ({view.filter_organizations.length})</label>
              <Checkbox.Group 
                className={'view-editor-checkbox-group'}
                disabled={savingView}
                onChange={handleFilterOrganizationsChange} 
                options={organizations.map(o => {
                  return {
                    label: o.title,
                    value: Number(o.id)
                  };
                })}
                value={view.filter_organizations ?? []}
              />
            </div>
          </div>
        </div>

        <div className={'view-editor-column'}>
          <p className={'view-editor-column-title'}>{t('settings')}</p>
          <div className={'view-editor-column-content'}>
            <div className={'view-editor-field'}>
              <label className={'view-editor-field-label'}>{t('name')}</label>
              <Input disabled={savingView} onChange={handleTitleChange} placeholder={'name'} size={'large'} value={view.title}/>
            </div>

            <div className={'view-editor-field'}>
              <label className={'view-editor-field-label'}>{t('share_with_organizations')}</label>
              <Checkbox.Group 
                className={'view-editor-checkbox-group'}
                disabled={savingView}
                onChange={handleShareOrganizationsChange} 
                options={organizations.map(o => {
                  return {
                    label: o.title,
                    value: o.id
                  };
                })}
                value={view.sharing?.organizations ?? []}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={'view-editor-footer'}>
        <Button className={'view-editor-save'} disabled={savingView} onClick={handleSave} type={'primary'}>
          {savingView ? <Spin size={'small'}/> : null}
          <p>{t('save')}</p>
        </Button>
        <Button className={'view-editor-cancel'} disabled={savingView} onClick={handleCancel}>{t('cancel')}</Button>
      </div>
    </div>
  );
}

export default withTranslation('benchmark')(ModalBenchmarkViewEditor);