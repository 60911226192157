import "./LevelFilter.scss";
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { withTranslation } from "react-i18next";

function LevelFilter(props) {
  const { levels, onLevelFiltersChanged, popoverId, t } = props;

  const [activeFilters, setActiveFilters] = useState([]);

  const handleFiltersChange = (values) => {
    setActiveFilters(values);
    onLevelFiltersChanged(values);
  }

  const handleFiltersClear = () => {
    setActiveFilters([]);
    onLevelFiltersChanged([]);
  }

  if (!!!levels)
    return (<></>);
  
  return (
    <div className={'level-filter'}>
      <div className={'level-filter-wrapper'}>

        <div className={'level-filter-header'}>
          <span className={"level=filter-title"}>{t('level')}</span>
          <div className={"level-filter-actions"}>
            {!!activeFilters.length && (
              <span className={"level-filter-clear"} onClick={handleFiltersClear}>{t(`filter_clear`)}</span>
            )}
          </div>
        </div>

        <div className={'level-filter-content'}>
          <Select
            allowClear={true} 
            getPopupContainer={() => document.getElementById(popoverId)} 
            mode='multiple' 
            onChange={handleFiltersChange}
            placeholder={t("select")}
            value={activeFilters}
          >
            {levels.map(l => (
              <Select.Option key={l.id} value={l.id}>
                {l.name}
              </Select.Option>
            ))}
          </Select>
        </div>

      </div>
    </div>
  );
}

export default withTranslation("benchmark")(LevelFilter);