import "./ModalDrilldownOrganization.scss";

import React, {useEffect, useState} from "react";
import { Spin, Table } from "antd";
import { withTranslation } from "react-i18next";

function ModalDrilldownOrganization(props) {
  const { data = [], element, loading = false, t } = props;

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: (text) => (<p>{text}</p>),
      title: t('name')      
    },
    {
      dataIndex: 'current',
      key: 'current',
      render: (text) => (<p>{text}</p>),
      title: t('result')
    }
  ];

  return (
    <div className={'mdo-wrapper'}>
      <div style={{fontSize: '1.2rem', marginBottom: '0.5em', width: '100%'}}>
        {element.title}
      </div>
      {loading ? <Spin size={'large'} /> : (
        <Table bordered columns={columns} dataSource={data} pagination={false} style={{ width: '100%' }} />
      )}
    </div>
  );
}

export default withTranslation('benchmark')(ModalDrilldownOrganization);