import React, { Component, Fragment } from "react";
import _, { isObject, isArray } from "lodash";

import {
  CaretRightOutlined,
  CloseOutlined,
  HistoryOutlined,
  LeftOutlined,
  MinusSquareOutlined,
  MoreOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
  RightOutlined,
  UploadOutlined,
  CloseCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import {
  Form,
  Collapse,
  Progress,
  Spin,
  Radio,
  Input,
  Checkbox,
  Select,
  Button,
  Upload,
  DatePicker,
  Modal,
  Popover,
  Popconfirm,
  message,
  Tooltip,
  Typography,
  Table,
  Tag,
  Menu,
} from "antd";
import { Comment } from "@ant-design/compatible";
import moment from "moment";
import classNames from "classnames";

import { withTranslation } from "react-i18next";
import { api } from "../../services";
import {
  MoreItem,
  CustomInputNumber,
  CustomInputMask,
  Loader,
  Loading,
} from "../../components";

import profilePic from "../../assets/images/svg/people-man.svg";
import iconChatText from "../../assets/images/svg/chat-text.svg";
import helpWheel from "../../assets/images/svg/icon-help-wheel.svg";
import iconFolder from "../../assets/images/svg/icon-folder.svg";

import "./Questionnaire.scss";
import { Link } from "react-router-dom";

import { SelectPeriods, Wrapper } from "./components";

import "moment/locale/nl";
import history from "../../history";
import House from "../../assets/images/svg/house";

moment.locale("nl");

const { TextArea } = Input;

const { Option } = Select;

const HelpComponent = (props) => {
  const { help, title, isActive, isRequired = false, onClick } = props;
  const style = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "18px",
    color: "#000",
    cursor: "pointer",
    borderBottom: "none",
  };

  const content = {
    display: "flex",
    justifyContent: "center",
    alignItems: "top",
  };

  const asterisk = {color: "red"};

  if (isActive)
    style.color = "#1369EA";

  return (
    <Fragment>
      <legend
        className={isRequired ? "required" : ""}
        style={style}
        onClick={onClick}
      >
        <div style={content}>
          {title}&nbsp; {isRequired && <div style={asterisk}>*</div>}
        </div>
        {help && (
          <Popover content={<div dangerouslySetInnerHTML={{ __html: help }} />}>
            <QuestionCircleOutlined
              style={{ cursor: "help", marginRight: 4 }}
            />
          </Popover>
        )}
      </legend>
    </Fragment>
  );
};

const MoreComponent = ({ more, moreTitle }) => (
  <Fragment>{more && <MoreItem text={more} title={moreTitle} />}</Fragment>
);

const historyColumns = [
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Webform",
    dataIndex: "webform",
  },
  {
    title: "Indicator",
    dataIndex: "indicator",
  },
  {
    title: "Indicator Value",
    dataIndex: "indicator_value",
  },
];

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      options: {},
      formKeys: [],
      fetch: false,
      data: {},
      initialData: {},
      activeCategoryData: null,
      showModal: false,
      showHistoryModal: false,
      isCompleting: false,
      isCompleted: false,
      isSubmitting: false,
      canReopen: false,
      isVisibleDetails: false,
      isBasicSubmitted: false,
      selectedField: {},
      remarks: [],
      fetchDetails: false,
      activeKey: null,
      remark: {
        text: "",
      },
      historyItem: null,
      historyValues: [],
      historyFetching: false,
      errors: {},
      warnings: {},
      reports: [],
      progress: {},
      selectedTags: props.app.tags
        .filter((i) => props.user.data.tags.includes(i.id))
        .map((i) => i.id),
      visibleModal: false,
      visibleSideBar: false,
      elementsAllModal: [],
      lineModalElements: [],
      activeItemModal: null,
      modalElements: [],
      mainItem: {},
      fetchModal: false,
      visibleContentDropdownKey: null,
      contentDropdownValues: [],
      formProgress: {},
      subInfo: {},
      users: [],
      disabledInput: true,
      questionnaireOrganization: {},
      redirectUrl: null,
      confirmationMessage: null,
      errorWebform: {},
      parentKey: null,
      changedOrganisation: null,
      valueOrganisation: {},
      defaultValuesOrganisation: {},
      isMultilevel: false,
      disabled: false,
      userInfo: {},
      updateForm: null,
      periodStart: null,
      allPeriods: null,
      submissionId: null,
      showLoader: false,
      dataIsChanged: [],
      wasChanges: false,
      activeOrganisation: null,
      parent_organisation: null,
      child_organisations: [],
    };
  }

  saveRemark = () => {
    const { match } = this.props;
    const {
      selectedField,
      remark: { text, remark_id: remarkId },
    } = this.state;

    api.questionnaires
      .sendRemarksField(match.params.id, selectedField.element_indicator, {
        data: { text, remark_id: remarkId },
      })
      .then((response) => {
        if (response.data.status) {
          this.setState({
            remark: {
              text: "",
            },
          });
        }
      })
      .then(() =>
        api.questionnaires.getRemarksField(
          match.params.id,
          selectedField.element_indicator
        )
      )
      .then((res) => {
        const { remarks } = res.data;

        this.setState({remarks: remarks || []});
      })
      .catch((error) => {});
  };

  handleDetails = (item) => {
    const { match } = this.props;
    const {
      options,
    } = this.state;

    if (!options.show_description_popup)
      return;

    this.setState({
      fetchDetails: true,
      activeKey: item.webform_key,
    });

    api.questionnaires
      .getRemarksField(match.params.id, item.element_indicator)
      .then((res) => {
        const { remarks } = res.data;

        this.setState({remarks: remarks || []});
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({fetchDetails: false});
      });

    this.setState({
      isVisibleDetails: true,
      selectedField: item,
    });
  };

  showDetails = () => {
    this.setState({
      isVisibleDetails: false,
      activeKey: false,
    });
  };

  handleEditorChange = (e) => {
    this.setState({
      remark: {
        ...this.state.remark,
        text: e.target.value,
      },
    });
  };

  openHistoryModal = (item) => {
    this.setState({
      showHistoryModal: true,
      historyItem: item,
    });
  };

  closeHistoryModal = () => {
    this.setState({
      showHistoryModal: false,
      historyItem: null,
      historyValues: [],
    });
  };

  handleDatePickerChange = ([startMoment, endMoment]) => {
    const {
      user: {
        data: { organisation },
      },
    } = this.props;

    const { historyItem } = this.state;

    if (!startMoment || !endMoment)
      return;

    const start = startMoment.format();
    const end = endMoment.format();

    const {
      app: { settings },
    } = this.props;

    const params = {
      start,
      end,
      siteId: settings.siteId,
      url: settings.url,
      indicator: historyItem.webform_indicator,
      organisation,
    };

    this.setState({historyFetching: true});

    return api.questionnaires.getHistoricalValues(params).then(({ data }) => {
      if (data.success) {
        this.setState({
          historyValues: data.values,
          historyFetching: false,
        });
      }
    });
  };

  onChange = (checkedValues) => {
    this.setState({ contentDropdownValues: checkedValues });
  };

  setVisibleContentDropdown = (e, item) => {
    e.stopPropagation();

    if (this.state.visibleContentDropdownKey)
      return;

    this.setState({
      visibleContentDropdownKey: item.webform_key,
      contentDropdownValues: (item.users || []).map((i) => i.id),
    });
  };

  closeContentDropdown = () => {
    const webformId = this.props.match.params.id;

    const { contentDropdownValues, visibleContentDropdownKey } = this.state;

    this.setState({
      visibleContentDropdownKey: null,
      contentDropdownValues: [],
    });
    return Promise.all([
      api.questionnaires.getAllCollapseElements(webformId),
      api.questionnaires.assignUsersToQuestion(
        webformId,
        visibleContentDropdownKey,
        { users: contentDropdownValues }
      ),
    ]).then(([responseCategories]) => {
      this.setState({
        elementsAllModal: responseCategories.data.webform.elements.map(
          this.buildStructure
        ),
      });
    });
  };

  mapElement = (item) => {
    const {
      title,
      type,
      // status,
      // options,
      webform_key: key,
      webform_children: children,
      webform_tags: tags,
      // more,
      // more_title: moreTitle,
      // help,
      // hide_empty: hideEmpty,
      basic_category: basicCategory,
      users: assignedUsers = [],
      // required
    } = item;

    const {
      isSubmitting,
      // isCompleted,
      formKeys,
      errors,
      warnings,
      // isBasicSubmitted,
      progress: stateProgress,
      selectedTags,
      visibleContentDropdownKey,
      contentDropdownValues,
      subInfo,
      users,
      errorWebform,
    } = this.state;

    const selectedItemTags = (tags || []).filter((i) => selectedTags.includes(i));

    if (selectedTags.length && !selectedItemTags.length)
      return null;

    const {
      t,
      user: {
        data: { permissions },
      },
    } = this.props;

    const progress = stateProgress[key] || { count: 0, filled: 0 };

    const collapseProps = {};

    collapseProps.activeKey = formKeys;

    const props = { hasFeedback: !["radios", "checkbox", "checkboxes"].includes(type) };

    if (isSubmitting)
      props.validateStatus = "validating";

    if (warnings[key]) {
      props.validateStatus = "warning";
      props.help = <div dangerouslySetInnerHTML={{ __html: warnings[key] }} />;
    }

    if (errors[key]) {
      props.validateStatus = "error";
      props.help = <div dangerouslySetInnerHTML={{ __html: errors[key] }} />;
    }

    const categorySubInfo = subInfo[item.webform_key] || {
      elements_count: 0,
      required_elements_count: 0,
    };

    const options = users.map((i) => ({ label: i.username, value: i.id }));

    const hardErrors = errorWebform[key]?.hard
      ? isObject(errorWebform[key]?.hard)
        ? Object.keys(errorWebform[key]?.hard)
        : errorWebform[key]?.hard
      : [];

    const softErrors = errorWebform[key]?.soft
      ? isObject(errorWebform[key]?.soft)
        ? Object.keys(errorWebform[key]?.soft)
        : errorWebform[key]?.soft
      : [];

    switch (type) {
      case "category_container":
        const helper = (item.elements || []).filter((i) => i.type === "category_container");
        if (helper.length) {
          return (
            <Collapse
              onChange={(v) => this.openModal({ v, item })}
              destroyInactivePanel
              {...collapseProps}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel
                key={key}
                header={
                  <div className="collapseHeader">
                    <div className="collapseHeaderMain">
                      <div className="title">{title}</div>
                      <div className="contentСollapseHeader">
                        {hardErrors?.length > 0 ? (
                          <div
                            style={{
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CloseCircleOutlined
                              style={{ color: "red", marginRight: "4px" }}
                            />
                            {hardErrors?.length} {t("error_message")}
                          </div>
                        ) : null}

                        {softErrors?.length > 0 ? (
                          <div
                            style={{
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <WarningOutlined
                              style={{ color: "orange", marginRight: "4px" }}
                            />
                            {softErrors?.length} {t("error_message")}
                          </div>
                        ) : null}

                        <div style={{ width: 110 }}>
                          <img
                            src={iconFolder}
                            alt=""
                            style={{ marginRight: 10 }}
                          />
                          {item.webform_children.length}{" "}
                          {item.webform_children.length === 1
                            ? t("group")
                            : t("groups")}
                        </div>
                        {progress.count_input > 0 && (
                          <div style={{ width: 130 }}>
                            <img
                              src={iconChatText}
                              alt=""
                              style={{ marginRight: 10 }}
                            />
                            {progress.count_input}{" "}
                            {progress.count_input === 1
                              ? t("general:question")
                              : t("general:questions")}{" "}
                            ({progress.count_required}
                            <span style={{ color: "red" }}>*</span>)
                          </div>
                        )}

                        {progress.count_input > 0 && (
                          <>
                            <Progress
                              status="active"
                              percent={
                                progress.count
                                  ? +parseFloat(
                                      (100 / progress.count_input) *
                                        progress.filled
                                    ).toFixed(1)
                                  : 0
                              }
                              size="small"
                              style={{ width: 150 }}
                              showInfo
                            />
                            <span style={{ color: "#21416f" }}>
                              <RightOutlined color="red" />
                            </span>
                          </>
                        )}
                        {progress.count_input === 0 && (
                          <>
                            <div style={{ width: 150 }}></div>
                          </>
                        )}
                      </div>
                    </div>
                    {permissions.can_assign_users_to_webform_question && (
                      <div className="collapseHeaderSelect">
                        <div className="containerDropdown">
                          <img
                            src={profilePic}
                            style={{ marginRight: 6 }}
                            alt=""
                          />
                          {assignedUsers.length ? (
                            <div
                              onClick={(e) => this.setVisibleContentDropdown(e, item)}
                            >
                              {assignedUsers[0].username}{" "}
                              {assignedUsers.length > 1
                                ? `(+${assignedUsers.length - 1} ${t("more")})`
                                : ""}
                            </div>
                          ) : (
                            <div
                              style={{ textDecoration: "underline" }}
                              onClick={(e) => this.setVisibleContentDropdown(e, item)}
                            >
                              {t("add_users")}
                            </div>
                          )}
                          <MoreOutlined />
                        </div>
                        <div
                          className="containerCheckbox"
                          style={{
                            display:
                              visibleContentDropdownKey === item.webform_key
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="helpElement" />
                          <div className="containerCheckbox-contant">
                            <div className="title">
                              {t("users_list")}{" "}
                              <span>
                                ({options.length} {t("users")})
                              </span>
                              <CloseOutlined
                                onClick={this.closeContentDropdown}
                              />
                            </div>
                            <div>
                              <Checkbox.Group
                                options={options}
                                onChange={this.onChange}
                                value={contentDropdownValues}
                                className="questionnaireCheckboxes"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
              >
                {children.length && !children[0].webform_key ? (
                  children ? (
                    <div className="d-flex justify-content-center mt-3">
                      <Spin />
                    </div>
                  ) : (
                    <div>Element doesn't have children</div>
                  )
                ) : (
                  <Fragment>
                    {children.map((el) => {
                      const element = this.mapElement(el, basicCategory);

                      return (
                        element && (
                          <div className="qs-item" key={el.webform_key}>
                            {element}
                          </div>
                        )
                      );
                    })}
                  </Fragment>
                )}
              </Collapse.Panel>
            </Collapse>
          );
        } 
        else {
          return (
            item.is_visible && (
              <div
                className="containerLinkCollapse"
                onClick={() => this.onVisibleModal(item.webform_key)}
              >
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                  <div className="title" style={{ width: "300px", marginRight: 30 }}>
                    {item.title}
                  </div>
                  {hardErrors?.length > 0 ? (
                    <div
                      style={{
                        minWidth: 160,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CloseCircleOutlined
                        style={{ color: "red", marginRight: "4px" }}
                      />
                      {hardErrors?.length} {t("error_message")}
                    </div>
                  ) : null}

                  {softErrors?.length > 0 ? (
                    <div
                      style={{
                        minWidth: 160,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <WarningOutlined
                        style={{ color: "orange", marginRight: "4px" }}
                      />
                      {softErrors?.length} {t("error_message")}
                    </div>
                  ) : null}
                  <div className="content">
                    <div style={{ opacity: 0, width: 110, height: 0 }}>
                      <HistoryOutlined
                        style={{ marginRight: 10, maxWidth: 200, opacity: 0 }}
                      />
                      Autosaved on 12-05-2020
                    </div>

                    {progress.count_input > 0 && (
                      <div style={{ width: 125 }}>
                        <img
                          src={iconChatText}
                          alt=""
                          style={{ marginRight: 10 }}
                        />
                        {progress.count_input}{" "}
                        {progress.count_input === 1
                          ? t("general:question")
                          : t("general:questions")}{" "}
                        ({progress.count_required}
                        <span style={{ color: "red" }}>*</span>)
                      </div>
                    )}

                    {progress.count_input > 0 && (
                      <>
                        {progress.count_input ? (
                          <Progress
                            status="active"
                            percent={
                              +parseFloat(
                                (100 / progress.count_input) * progress.filled
                              ).toFixed(1)
                            }
                            size="small"
                            style={{ width: 150 }}
                            showInfo
                          />
                        ) : (
                          <Progress
                            status="active"
                            percent={0}
                            size="small"
                            style={{ width: 150 }}
                            showInfo
                          />
                        )}
                      </>
                    )}
                    {progress.count_input === 0 && (
                      <>
                        <div style={{ width: 150 }}></div>
                      </>
                    )}
                    <span style={{ color: "#21416f", marginLeft: -25 }}>
                      <RightOutlined color="red" />
                    </span>
                  </div>
                </div>
                {permissions.can_assign_users_to_webform_question && (
                  <div className="collapseHeaderSelect">
                    <div className="containerDropdown">
                      <img src={profilePic} style={{ marginRight: 6 }} alt="" />
                      {assignedUsers.length ? (
                        <div onClick={(e) => this.setVisibleContentDropdown(e, item)}>
                          {assignedUsers[0].username}{" "}
                          {assignedUsers.length > 1 ? `(+${assignedUsers.length - 1} ${t("more")})` : ""}
                        </div>
                      ) : (
                        <div style={{ textDecoration: "underline" }} onClick={(e) => this.setVisibleContentDropdown(e, item)}>
                          {t("add_users")}
                        </div>
                      )}
                      <MoreOutlined />
                    </div>

                    <div className="containerCheckbox" style={{display: visibleContentDropdownKey === item.webform_key ? "block" : "none"}}>
                      <div className="helpElement" />
                      <div className="containerCheckbox-contant">
                        <div className="title">
                          {t("users_list")}{" "}
                          <span>({options.length} {t("users")})</span>
                          <CloseOutlined onClick={this.closeContentDropdown} />
                        </div>
                        <div>
                          <Checkbox.Group
                            options={options}
                            onChange={this.onChange}
                            value={contentDropdownValues}
                            className="questionnaireCheckboxes"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          );
        }

      default:
        console.error(`Element ${type} not found`, item);
        return null;
    }
  };

  mapElementModal = (
    item,
    isBasic = false,
    showHelpComponent = true,
    organisationID = this.props.user.data.organisation,
    multiset = false,
    showMoreComponent = true
  ) => {
    const {
      title,
      type,
      options,
      is_multilevel: multilevel,
      webform_key: key,
      webform_parent_key: parentKey,
      webform_tags: tags,
      more,
      more_title: moreTitle,
      help,
      hide_empty: hideEmpty,
      required,
      readonly,
      field_prefix: prefix,
      field_suffix: suffix,
      input_mask: mask,
      placeholder,
      is_numeric: isNumeric,
      numeric_decimal: itemDecimal,
      numeric_thousand: itemThousand,
      decimal_places: itemDecimalPlaces,
      numeric_settings_level_overwrite: numericSettingsLevelOverwrite,
      is_user_can_override: isUserCanOverride,
    } = item;

    const decimal = itemDecimal ?? ",";
    const thousand = itemThousand ?? ".";
    const decimalPlaces = itemDecimalPlaces ?? 2;

    const {
      data,
      isSubmitting,
      isCompleted,
      formKeys,
      activeKey,
      errors,
      initialData,
      warnings,
      isBasicSubmitted,
      selectedTags,
      options: formOptions,
      disabledInput,
      changedOrganisation,
      valueOrganisation,
      defaultValuesOrganisation,
      isMultilevel,
      disabled,
    } = this.state;
   
    const isInactiveData = formOptions.data_entry !== "active";

    const selectedItemTags = tags.filter((i) => selectedTags.includes(i));

    if (selectedTags.length && !selectedItemTags.length)
      return null;

    const collapseProps = {};
    const isActive = key === activeKey;

    collapseProps.activeKey = formKeys;

    const props = {hasFeedback: !["radios", "checkbox", "checkboxes"].includes(type)};

    if (isSubmitting)
      props.validateStatus = "validating";

    if (warnings[key]) {
      props.validateStatus = "warning";
      props.help = <div dangerouslySetInnerHTML={{ __html: warnings[key] }} />;
    }

    if (errors[key]) {
      props.validateStatus = "error";
      props.help = <div dangerouslySetInnerHTML={{ __html: errors[key] }} />;
    }

    let hasAutocalculate;
    let isOverwritten;
    let displayValue;

    if (isObject(data[key]))
      hasAutocalculate = "calculated" in data[key];

    displayValue = valueOrganisation[organisationID + "," + key];
    let defaultValue = defaultValuesOrganisation[organisationID + "," + key];

    const handlerDisabledInput = (e) => {
      this.setState({disabledInput: e.target.checked});
    };

    const checkRegex = (v) => {
      let str = v;
      if (thousand)
        str = str?.replace(new RegExp("\\" + thousand, "g"), "");

      if (decimal)
        str = str?.replace(new RegExp("\\" + decimal, "g"), ".");

      return str;
    };
    
    const has_parent = (!!this.state.parent_organisation);
    const is_parent = (!!this.state.child_organisations.length);

    const input_disabled = !!readonly // Field is readonly
      || !!disabled // Field is disabled
      || !!isCompleted // Submission has already been completed
      || (!!multilevel && !!hasAutocalculate && is_parent) // Multilevel Propagation and is parent
      || (!(!!multilevel) && !!hasAutocalculate) // Autocalculated 
      || (!!multilevel && !(!!isUserCanOverride) && organisationID != this.props.user.data.organisation) // Multilevel field for another organisation and UserOverride is disabled
      || !!(multiset && defaultValue) // multiset is always false so this one is useless...
    ;

    switch (type) {
      case "radios":
        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  isActive={isActive}
                  help={help}
                  isRequired={required}
                />
              )}
              <Form.Item {...props}>
                <Radio.Group
                  onChange={(e) =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      String(e.target.value),
                      required,
                      organisationID,
                      multiset
                    )
                  }
                  onBlur={(e) =>
                    this.handleItemBlur(key, required, e.target.value)
                  }
                  value={displayValue ? parseInt(displayValue) : null}
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    !!readonly ||
                    isCompleted
                  }
                >
                  <div className="containerRadioButton">
                    {options?.map((el) => (
                      <Radio
                        key={el.key}
                        value={el.key}
                        style={{ display: "block" }}
                      >
                        {el.value}
                      </Radio>
                    ))}
                  </div>
                </Radio.Group>
              </Form.Item>
              {!(!!readonly) && hasAutocalculate && !!isOverwritten && (
                <Button
                  onClick={() =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      "",
                      required,
                      organisationID,
                      multiset
                    )
                  }
                >
                  Waarde berekenen
                </Button>
              )}
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );

      case "checkbox":
        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              <Form.Item {...props}>
                <Checkbox
                  key={key}
                  onChange={(e) =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      e.target.checked ? 1 : 0,
                      required,
                      organisationID,
                      multiset
                    )
                  }
                  onBlur={(e) => this.handleItemBlur(key, required, e.target.checked ? 1 : 0)}
                  defaultChecked={
                    !!(isNaN(displayValue)
                      ? displayValue
                      : Number(displayValue))
                  }
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    !!readonly ||
                    isCompleted
                  }
                />
              </Form.Item>
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );

      case "checkboxes":
        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              <Form.Item {...props}>
                <Checkbox.Group
                  key={key}
                  defaultValue={displayValue}
                  value={displayValue}
                  onChange={(v) =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      v,
                      required,
                      organisationID,
                      multiset
                    )
                  }
                  onBlur={(v) => this.handleItemBlur(key, required, v)}
                  className="questionnaireCheckboxes"
                  options={options?.map((i) => ({
                    label: i.value,
                    value: String(i.key),
                  }))}
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    !!readonly ||
                    isCompleted
                  }
                />
              </Form.Item>
              {!(!!readonly) && hasAutocalculate && !!isOverwritten && (
                <Button
                  onClick={() =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      "",
                      required,
                      organisationID,
                      multiset
                    )
                  }
                >
                  Waarde berekenen
                </Button>
              )}
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );

      case "select":
        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              <Form.Item {...props}>
                <Select
                  key={key}
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    !!readonly ||
                    isCompleted
                  }
                  style={{ width: "100%" }}
                  onChange={(v) =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      v,
                      required,
                      organisationID,
                      multiset
                    )
                  }
                  onBlur={(v) => this.handleItemBlur(key, required, v)}
                  defaultValue={displayValue}
                  value={displayValue}
                  className="questionnairesSelect"
                >
                  {options.map((el) => (
                    <Select.Option value={el.key}>{el.value}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {!(!!readonly) && hasAutocalculate && !!isOverwritten && (
                <Button
                  onClick={() =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      "",
                      required,
                      organisationID,
                      multiset
                    )
                  }
                >
                  Waarde berekenen
                </Button>
              )}
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );

      case "textarea":
        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              <Form.Item {...props}>
                <Input.TextArea
                  key={key}
                  defaultValue={displayValue}
                  className="questionnaireTextArea"
                  onChange={(e) =>
                    this.debounceDefault(
                      key,
                      parentKey,
                      e.target.value,
                      required,
                      organisationID,
                      multiset
                    )
                  }
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    !!readonly ||
                    isCompleted
                  }
                />
              </Form.Item>
              {!(!!readonly) && hasAutocalculate && !!isOverwritten && (
                <Button
                  onClick={() =>
                    this.debounceQuick(
                      key,
                      parentKey,
                      "",
                      required,
                      organisationID,
                      multiset
                    )
                  }
                >
                  Waarde berekenen
                </Button>
              )}
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );
      case "number":
      case "textfield": {
        const inputProps = {};
        if (prefix) inputProps.prefix = prefix;
        if (suffix) inputProps.suffix = suffix;
        if (placeholder) inputProps.placeholder = placeholder;
        if (mask) inputProps.mask = mask;

        return (
          <Form key={organisationID}>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              <Form.Item {...props} key={organisationID}>
                {(type == 'number' && !mask) ? (
                  <CustomInputNumber
                    key={key}
                    {...inputProps}
                    thousandSeparator={thousand}
                    decimalSeparator={decimal}
                    decimalScale={Number(decimalPlaces)}
                    placeholder={placeholder}
                    onValueChange={(v) =>
                      this.debounceDefault(
                        key,
                        parentKey,
                        checkRegex(v.formattedValue),
                        required,
                        organisationID,
                        multiset
                      )
                    }
                    value={
                      displayValue &&
                      displayValue.toString().replace(/\./g, decimal)
                    }
                    disabled={!!input_disabled}
                  />
                ) : (!mask ? (
                  <Input defaultValue={displayValue} 
                    disabled={!!input_disabled}
                    onChange={(e) =>
                      this.debounceDefault(
                        key,
                        parentKey,
                        e.target.value,
                        required,
                        organisationID,
                        multiset
                      )
                    } 
                    placeholder={placeholder} 
                    type="text"/>
                ) : (
                  <CustomInputMask
                    {...inputProps}
                    key={key}
                    placeholder={placeholder}
                    onChange={(e) =>
                      this.debounceDefault(
                        key,
                        parentKey,
                        e.target.value,
                        required,
                        organisationID,
                        multiset
                      )
                    }
                    mask={mask}
                    defaultValue={displayValue}
                    disabled={!!input_disabled}
                  />
                ))}
              </Form.Item>
              {!(!!readonly) && hasAutocalculate && !!isOverwritten && (
                <Button onClick={() => this.debounceQuick(key, parentKey, "", required)}>Waarde berekenen</Button>
              )}
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );
      }

      case "webform_document_file":
      case "managed_file":
        return (
          <div className="qs-area">
            {showHelpComponent && (
              <HelpComponent
                title={title}
                help={help}
                isActive={isActive}
                isRequired={required}
              />
            )}
            <Upload>
              <Button>
                <UploadOutlined
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    (isBasic && isBasicSubmitted) ||
                    isCompleted ||
                    isInactiveData
                  }
                />{" "}
                Click to Upload
              </Button>
            </Upload>
            {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
          </div>
        );

      case "date":
        const opts = {onChange: (d, v) => this.debounceQuick(key, parentKey, v)};

        if (displayValue)
          opts.value = moment(displayValue);

        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={title}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              <Form.Item {...props}>
                <DatePicker
                  {...opts}
                  disabled={
                    (multiset && defaultValue) ||
                    disabled ||
                    (isBasic && isBasicSubmitted) ||
                    isCompleted ||
                    isInactiveData ||
                    isSubmitting
                  }
                />
              </Form.Item>
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );

      case "webform_markup":
        return (
          <Fragment>
            <div className="titleBlock">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: item.markup }}
              />
            </div>
          </Fragment>
        );

      case "webform_computed_twig":
        return (
          (!hideEmpty || displayValue) && (
            <Fragment>
              <div className="qs-area">
                {showHelpComponent && (
                  <HelpComponent
                    title={title}
                    help={help}
                    isActive={isActive}
                    isRequired={required}
                  />
                )}
                <legend>{displayValue}</legend>
                {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
              </div>
            </Fragment>
          )
        );

      case "webform_autocalculated": {
        const fieldProps = {};
        if (prefix) fieldProps.prefix = prefix;
        if (suffix) fieldProps.suffix = suffix;
        if (placeholder) fieldProps.placeholder = placeholder;
        const newTitle = `${title} ${!!readonly ? "(Read only)" : ""}`;

        return (
          <Form>
            <div className="qs-area">
              {showHelpComponent && (
                <HelpComponent
                  title={newTitle}
                  help={help}
                  isActive={isActive}
                  isRequired={required}
                />
              )}
              {!!readonly ? (
                <legend>{displayValue}</legend>
              ) : (
                <Form.Item {...props} name={key}>
                  {isNumeric ? (
                    <>
                      <CustomInputNumber
                        {...fieldProps}
                        key={key}
                        allowClear
                        thousandSeparator={thousand}
                        decimalSeparator={decimal}
                        decimalScale={Number(decimalPlaces)}
                        placeholder={placeholder}
                        onValueChange={(v) =>
                          this.debounceDefault(
                            key,
                            parentKey,
                            v.formattedValue.replace(/\./g, ""),
                            organisationID,
                            multiset
                          )
                        }
                        value={
                          displayValue &&
                          displayValue.toString().replace(/\./g, decimal)
                        }
                        disabled={
                          (multiset && defaultValue) ||
                          disabled ||
                          !!hasAutocalculate ||
                          (isBasic && isBasicSubmitted) ||
                          isCompleted ||
                          isInactiveData
                        }
                      />
                    </>
                  ) : (
                    <Input
                      key={key}
                      onChange={(e) => {
                        const v = e.target.value;
                        this.setState((prev) => ({
                          data: {
                            ...prev.data,
                            [key]: v,
                          },
                        }));
                        this.debounceDefault(
                          key,
                          parentKey,
                          v,
                          required,
                          organisationID,
                          multiset
                        );
                      }}
                      allowClear
                      mask={mask}
                      value={displayValue}
                      disabled={
                        (multiset && defaultValue) ||
                        disabled ||
                        !!hasAutocalculate ||
                        (isBasic && isBasicSubmitted) ||
                        isCompleted ||
                        isInactiveData
                      }
                    />
                  )}
                </Form.Item>
              )}
              {showMoreComponent && <MoreComponent more={more} moreTitle={moreTitle} />}
            </div>
          </Form>
        );
      }

      default:
        console.error(`Element ${type} not found`, item);
        return null;
    }
  };

  onVisibleModal = (key, link, reload = false) => {
    if (this.state.visibleContentDropdownKey)
      return;

    if (+key) {
      this.setState({fetchModal: true});
      this.setState({parentKey: key});

      this.fetchData(key)
        .then((data) => {
          this.setState({
            activeItemModal: key,
            modalElements: data,
          });
        })
        .finally(() => {
          this.setState({fetchModal: false});
        });
    }

    if (reload) {
      this.fetchData(null)
        .then((data) => {
          this.setState({
            activeItemModal: key,
            modalElements: data,
          });
        })
        .finally(() => {
          this.setState({fetchModal: false});
        });
    }

    if (!link) {
      this.setState({
        visibleModal: !this.state.visibleModal,
        errors: {},
      });
    }
  };

  getFilteredCategoriesByUserTags = () => {
    const { lineModalElements } = this.state;
    const userTags = this.props.app.tags
      .filter((i) => this.props.user.data.tags.includes(i.id))
      .map((i) => i.id);

    if (userTags.length) {
      const filteredLineModalElementsByCategory = lineModalElements.reduce(
        (acc, i) => {
          const intersection = userTags.filter((x) => i.webform_tags.includes(x));

          if (intersection.length) return [...acc, i];
          else return acc;
        },
        []
      );

      return filteredLineModalElementsByCategory;
    } 
    else
      return lineModalElements;
  };

  IsJsonString = (str) => {
    try {
      const json = JSON.parse(str);
      return typeof json === "object";
    } 
    catch (e) {
      return false;
    }
  };

  startFilling = () => {
    const { lineModalElements } = this.state;
    const userTags = this.props.app.tags
      .filter((i) => this.props.user.data.tags.includes(i.id))
      .map((i) => i.id);

    if (userTags.length) {
      const filteredLineModalElementsByCategory = lineModalElements.reduce(
        (acc, i) => {
          const intersection = userTags.filter((x) =>
            i.webform_tags.includes(x)
          );

          if (intersection.length) return [...acc, i];
          else return acc;
        },
        []
      );

      this.onVisibleModal(filteredLineModalElementsByCategory[0].webform_key);
    } 
    else
      this.onVisibleModal(lineModalElements[0].webform_key);
  };

  deepSearch = (elements, key, { data, spinner }) => {
    return elements.map((item) => {
      if (item.webform_key === key) {
        const obj = {};

        if (data)
          obj.webform_children = data;

        return { ...item, ...obj, spinner };
      } 
      else if (item.webform_children && item.webform_children.length) {
        item.webform_children = this.deepSearch(item.webform_children, key, {
          data,
          spinner,
        });
      }

      return item;
    });
  };

  findKeys = (item) => {
    const keys = [item.webform_key];
    if (item.webform_children && item.webform_children.length) {
      if (item.webform_children[0].webform_key) {
        return item.webform_children.reduce(
          (acc, el) => acc.concat(this.findKeys(el)),
          keys
        );
      }

      return keys.concat(item.webform_children);
    }

    return keys;
  };

  handleAccordion = ({ v: keys, item }) => {
    const { elements, formKeys, data: stateData, initialData } = this.state;

    if (!formKeys.includes(item.webform_key)) {
      this.setState({
        elements: this.deepSearch(elements, item.webform_key, { spinner: true }),
        formKeys: formKeys.concat([item.webform_key]),
      });

      if (item.webform_children && item.webform_children.length) {
        this.fetchData(keys[keys.length - 1]).then((data) => {
          data.forEach((item) => {
            const prevData = { ...this.state.isMultilevel };
            this.setState({
              isMultilevel: {
                ...prevData,
                [item.webform_key]: item.is_multilevel,
              },
            });
          });

          this.setState({
            modalElements: data,
            elements: this.deepSearch(elements, item.webform_key, {
              data,
              spinner: false,
            }),
          });
        });
      } 
      else {
        this.setState({
          elements: this.deepSearch(elements, item.webform_key, { spinner: false }),
        });
      }
    } 
    else {
      const keysToClose = this.findKeys(item);

      const newData = keysToClose.reduce(
        (acc, key) => ({ ...acc, [key]: initialData[key] }),
        { ...stateData }
      );

      this.setState({
        elements: this.deepSearch(elements, item.webform_key, { spinner: false }),
        formKeys: formKeys.filter((key) => !keysToClose.includes(key)),
        data: newData,
      });
    }

    this.setState({ isVisibleDetails: false });
  };

  fetchBenchmarkPeriods = async (periodData = null) => {
    const webformId = this.props.match.params.id;
    let periodStart = periodData || this.state.periodStart;

    if (!periodStart) {
      await this.props
        .getSubmissionPeriods({ data: webformId })
        .then((response) => {
          const {
            data: {values: { data }},
          } = response;
          if (data) {
            periodStart =
              moment(
                data[0]?.condition?.period_start,
                "YYYY-MM-DD hh:mm:ss"
              ).valueOf() / 1000;

            if (!this.state.periodStart) {
              this.setState({
                periodStart:
                  moment(
                    data[0]?.condition?.period_start,
                    "YYYY-MM-DD hh:mm:ss"
                  ).valueOf() / 1000,
                allPeriods: data,
              });
            }
          }
        });
    }

    return periodStart;
  };

  fetchElements = async (parentKey, organisationID = this.props.user.data.organisation, periodData = null) => {
    const webformId = this.props.match.params.id;
    let periodStart = null;
    await this.fetchBenchmarkPeriods(periodData)
      .then((period) => {
        periodStart = period
      });


    let elements = api.questionnaires.getElements(
      webformId,
      parentKey,
      false,
      organisationID,
      periodStart
    )
    .then((response) => {
      this.setState({options: response.data.webform.options});

      const errorObject = { ...this.state.errorWebform };
      response.data.webform.elements.forEach((item) => (errorObject[item.webform_key] = item.validation));
      this.setState({errorWebform: errorObject});

      return response.data.webform.elements;
    })
    .catch((e) => console.error("error ", e));

    return elements || [];
  };

  processSubmission = (submissionData) => {
    this.setState({
      parent_organisation: submissionData.parent_organisation,
      child_organisations: submissionData.child_organisations
    });

    if (isObject(submissionData.validation) && submissionData.validation != null)
      this.setState({
        errors: submissionData.validation.errors || {},
        warnings: submissionData.validation.warnings || {},
      });

    const initialData = submissionData.data
      .reduce((acc, i) => ({ ...acc, [i.key]: i.value }), {
        ...this.state.data,
      });
    
    this.setState({
      submissionId: {
        ...this.state.submissionId,
        [submissionData.organisation_id]: submissionData.submission_id,
      },
    });

    const isDefaultValues = submissionData.data?.reduce(
      (acc, i) => ({
        ...acc,
        [i.key]: i.is_default_value,
      }),
      { ...this.state.data }
    );

    const formattedData = Object.keys(initialData).reduce((acc, i) => {
      const isObject = initialData[i] && this.IsJsonString(initialData[i]);
      if (isObject) 
        return { ...acc, [i]: JSON.parse(initialData[i]) };
      else 
        return { ...acc, [i]: initialData[i] };
    }, {});

    const roleData = [];
    //const sortRole = submissionData.user?.roles?.forEach((item) => roleData.push(item.target_id));

    this.setState({
      userInfo: {
        ...this.state.userInfo,
        [submissionData.organisation_id]: {
          name: submissionData.user?.name?.[0]?.value,
          role: roleData,
        },
      },
      updateForm: {
        ...this.state.updateForm,
        [submissionData.organisation_id]: submissionData.updated_at,
      },
    });

    Object.entries(isDefaultValues).forEach((item) => {
      const [key, isDefault] = item;

      this.setState({
        defaultValuesOrganisation: {
          ...this.state.defaultValuesOrganisation,
          [submissionData.organisation_id + "," + key]: isDefault,
        },
      });
    });

    Object.entries(formattedData).forEach((item) => {
      const [key, value] = item;
      let currentValue = null;

      if (isArray(value)) currentValue = value;
      else if (isObject(value)) currentValue = value?.set || value?.calculated || null;
      else currentValue = value;

      this.setState({
        valueOrganisation: {
          ...this.state.valueOrganisation,
          [submissionData.organisation_id + "," + key]: currentValue,
        },
      });
    });

    // const stateProgress = ((submissionData.progress && submissionData.progress.elements) || []).reduce(
    //   (acc, el) => ({ ...acc, [el.key]: el }),
    //   {}
    // );

    // this.setState({
    //   data: { ...formattedData },
    //   initialData: initialData,
    //   isCompleted: !!submissionData.completed,
    //   canReopen: !!submissionData.permissions && submissionData.permissions.can_reopen,
    //   isBasicSubmitted: submissionData.progress && submissionData.progress.basic.filled_required === submissionData.progress.basic.count_required,
    //   formProgress: submissionData.progress && submissionData.progress.form,
    //   progress: stateProgress
    // });

    // const findOrganization = this.props.user.data.organisations.find((item) => item.id === submissionData.organisation_id);
    // this.setState({
    //   questionnaireOrganization: findOrganization,
    //   redirectUrl: submissionData.confirmation_url,
    //   confirmationMessage: submissionData.confirmation_message,
    // });

    if (submissionData.data && submissionData.data.error && submissionData.data.error.length)
      submissionData.data.errors.forEach((err) => message.error(err));
  };

  fetchSubmissions = async(parentKey, organisationIds = [this.props.user.data.organisation], periodData = null) => {
    const webformId = this.props.match.params.id;
    let periodStart = null; 
    await this.fetchBenchmarkPeriods(periodData)
      .then((period) => {
        periodStart = period
      });

    await api.questionnaires.getSubmissions(
      webformId,
      parentKey,
      organisationIds,
      periodStart
    )
    .then((response) => {
      var responseData = Object.values(response.data);
      responseData.forEach((organisationData) => this.processSubmission(organisationData));
    })
    .catch((e) => console.error("error ", e));
  }

  fetchSubmission = async (parentKey, organisationID = this.props.user.data.organisation, periodData = null) => {
    const webformId = this.props.match.params.id;
    let periodStart = null; 
    await this.fetchBenchmarkPeriods(periodData)
      .then((period) => {
        periodStart = period
      });

    await api.questionnaires.getSubmission(
      webformId,
      parentKey,
      organisationID,
      periodStart
    )
    .then(({data: {
      permissions,
      data,
      completed,
      progress,
      organisation_id,
      confirmation_url,
      confirmation_message,
      user,
      updated_at,
      submission_id,
      parent_organisation,
      child_organisations,
      validation
    }}) => {
      this.setState({
        parent_organisation: parent_organisation,
        child_organisations: child_organisations
      });

      if (isObject(validation) && validation != null)
        this.setState({
          errors: validation.errors || {},
          warnings: validation.warnings || {},
        });

      const initialData = data
        .reduce((acc, i) => ({ ...acc, [i.key]: i.value }), {
          ...this.state.data,
        });
      
      this.setState({
        submissionId: {
          ...this.state.submissionId,
          [organisationID]: submission_id,
        },
      });

      const isDefaultValues = data?.reduce(
        (acc, i) => ({
          ...acc,
          [i.key]: i.is_default_value,
        }),
        { ...this.state.data }
      );

      const formattedData = Object.keys(initialData).reduce((acc, i) => {
        const isObject = initialData[i] && this.IsJsonString(initialData[i]);
        if (isObject) 
          return { ...acc, [i]: JSON.parse(initialData[i]) };
        else 
          return { ...acc, [i]: initialData[i] };
      }, {});

      const roleData = [];
      const sortRole = user?.roles?.forEach((item) => roleData.push(item.target_id));

      this.setState({
        userInfo: {
          ...this.state.userInfo,
          [organisationID]: {
            name: user?.name?.[0]?.value,
            role: roleData,
          },
        },
        updateForm: {
          ...this.state.updateForm,
          [organisationID]: updated_at,
        },
      });

      Object.entries(isDefaultValues).forEach((item) => {
        const [key, isDefault] = item;

        this.setState({
          defaultValuesOrganisation: {
            ...this.state.defaultValuesOrganisation,
            [organisationID + "," + key]: isDefault,
          },
        });
      });

      Object.entries(formattedData).forEach((item) => {
        const [key, value] = item;
        let currentValue = null;

        if (isArray(value)) currentValue = value;
        else if (isObject(value)) currentValue = value?.set || value?.calculated || null;
        else currentValue = value;

        this.setState({
          valueOrganisation: {
            ...this.state.valueOrganisation,
            [organisationID + "," + key]: currentValue,
          },
        });
      });

      const stateProgress = ((progress && progress.elements) || []).reduce(
        (acc, el) => ({ ...acc, [el.key]: el }),
        {}
      );

      this.setState({
        data: { ...formattedData },
        initialData: initialData,
        isCompleted: !!completed,
        canReopen: !!permissions && permissions.can_reopen,
        isBasicSubmitted: progress && progress.basic.filled_required === progress.basic.count_required,
        formProgress: progress && progress.form,
        progress: stateProgress
      });

      const findOrganization = this.props.user.data.organisations.find((item) => item.id === organisation_id);
      this.setState({
        questionnaireOrganization: findOrganization,
        redirectUrl: confirmation_url,
        confirmationMessage: confirmation_message,
      });

      if (data && data.error && data.error.length)
        data.errors.forEach((err) => message.error(err));
    })
    .catch((e) => console.error("error ", e));
  };

  fetchCollapseElements = async (organisationID = this.props.user.data.organisation, periodData = null) => {
    const webformId = this.props.match.params.id;
    let periodStart = null;
    await this.fetchBenchmarkPeriods(periodData)
      .then((period) => {
        periodStart = period
      });

    await api.questionnaires.getAllCollapseElements(
      webformId,
      null,
      null,
      organisationID,
      periodStart
    )
    .then((responseCategories) => {
      let elementsAllModal = responseCategories.data.webform.elements.map(this.buildStructure);
      this.setState({
        elementsAllModal: elementsAllModal,
        lineModalElements: this.getInnerElements(elementsAllModal),
        users: responseCategories.data.webform.users || [],
        subInfo: this.getInnerSubInfo(elementsAllModal).reduce(
          (acc, i) => ({ ...acc, [i.webform_key]:  i }),
          {}
        )
      });
    })
    .catch((e) => console.error("error ", e));
  };

  // fetchData = async (
  //   parentKey,
  //   onlyCategories = false,
  //   organisationID = this.props.user.data.organisation,
  //   periodData = null
  // ) => {
  //   if (isArray(organisationID))
  //     await this.fetchSubmissions(parentKey, organisationID, periodData);
  //   else
  //     await this.fetchSubmission(parentKey, organisationID, periodData);

  //   let elements = (onlyCategories ? this.state.elements : this.state.modalElements);

  //   if (elements.length == 0)
  //     await this.fetchElements(parentKey, organisationID, periodData)
  //       .then((fetchedElements) => {
  //         elements = fetchedElements;
  //       });

  //   if (onlyCategories)
  //     this.fetchCollapseElements(organisationID, periodData);
    
  //   return elements;
  // };

  fetchData = async (
    parentKey,
    onlyCategories = false,
    organisationID = this.props.user.data.organisation,
    periodData = null
  ) => {
    const webformId = this.props.match.params.id;
    let periodStart = periodData || this.state.periodStart;

    if (!periodStart) {
      await this.props
        .getSubmissionPeriods({ data: webformId })
        .then((response) => {
          const {
            data: {values: { data }},
          } = response;
          if (data) {
            periodStart =
              moment(
                data[0]?.condition?.period_start,
                "YYYY-MM-DD hh:mm:ss"
              ).valueOf() / 1000;

            if (!this.state.periodStart) {
              this.setState({
                periodStart:
                  moment(
                    data[0]?.condition?.period_start,
                    "YYYY-MM-DD hh:mm:ss"
                  ).valueOf() / 1000,
                allPeriods: data,
              });
            }
          }
        });
    }

    const promises = [
      api.questionnaires.getElements(
        webformId,
        parentKey,
        false,
        organisationID,
        periodStart
      ),
      api.questionnaires.getSubmission(
        webformId,
        parentKey,
        organisationID,
        periodStart
      ),
    ];

    if (onlyCategories) {
      promises.push(
        api.questionnaires.getAllCollapseElements(
          webformId,
          null,
          null,
          organisationID,
          periodStart
        )
      );
    }

    return Promise.all(promises)
      .then(
        ([
          response,
          {
            data: {
              permissions,
              data,
              completed,
              progress,
              organisation_id,
              confirmation_url,
              confirmation_message,
              user,
              updated_at,
              submission_id,
              parent_organisation,
              child_organisations,
              validation
            },
          },
          responseCategories,
        ]) => {
          this.setState({
            parent_organisation: parent_organisation,
            child_organisations: child_organisations
          });
          
          if (isObject(validation) && validation != null)
            this.setState({
              errors: validation.errors || {},
              warnings: validation.warnings || {},
            });

          const initialData = data
            .reduce((acc, i) => ({ ...acc, [i.key]: i.value }), {
              ...this.state.data,
            });
          
          this.setState({
            submissionId: {
              ...this.state.submissionId,
              [organisationID]: submission_id,
            },
          });

          const isDefaultValues = data?.reduce(
            (acc, i) => ({
              ...acc,
              [i.key]: i.is_default_value,
            }),
            { ...this.state.data }
          );

          const formattedData = Object.keys(initialData).reduce((acc, i) => {
            const isObject = initialData[i] && this.IsJsonString(initialData[i]);
            if (isObject) 
              return { ...acc, [i]: JSON.parse(initialData[i]) };
            else 
              return { ...acc, [i]: initialData[i] };
          }, {});

          const roleData = [];
          const sortRole = user?.roles?.forEach((item) => roleData.push(item.target_id));

          this.setState({
            userInfo: {
              ...this.state.userInfo,
              [organisationID]: {
                name: user?.name?.[0]?.value,
                role: roleData,
              },
            },
            updateForm: {
              ...this.state.updateForm,
              [organisationID]: updated_at,
            },
          });

          Object.entries(isDefaultValues).forEach((item) => {
            const [key, isDefault] = item;

            this.setState({
              defaultValuesOrganisation: {
                ...this.state.defaultValuesOrganisation,
                [organisationID + "," + key]: isDefault,
              },
            });
          });

          Object.entries(formattedData).forEach((item) => {
            const [key, value] = item;
            let currentValue = null;

            if (isArray(value)) currentValue = value;
            else if (isObject(value)) currentValue = value?.set || value?.calculated || null;
            else currentValue = value;

            this.setState({
              valueOrganisation: {
                ...this.state.valueOrganisation,
                [organisationID + "," + key]: currentValue,
              },
            });
          });

          const stateProgress = ((progress && progress.elements) || []).reduce(
            (acc, el) => ({ ...acc, [el.key]: el }),
            {}
          );
          const state = {
            data: { ...formattedData },
            initialData,
            isCompleted: !!completed,
            canReopen: !!permissions && permissions.can_reopen,
            options: response.data.webform.options,
            isBasicSubmitted:
              progress &&
              progress.basic.filled_required === progress.basic.count_required,
            formProgress: progress && progress.form,
            progress: stateProgress,
          };

          const findOrganization = this.props.user.data.organisations.find((item) => item.id === organisation_id);

          this.setState({
            questionnaireOrganization: findOrganization,
            redirectUrl: confirmation_url,
            confirmationMessage: confirmation_message,
          });

          const errorObject = { ...this.state.errorWebform };

          response.data.webform.elements.forEach((item) => (errorObject[item.webform_key] = item.validation));

          this.setState({errorWebform: errorObject});

          if (onlyCategories) {
            let elementsAllModal = responseCategories.data.webform.elements.map(this.buildStructure);
            state.elementsAllModal = elementsAllModal;
            state.lineModalElements = this.getInnerElements(elementsAllModal);
            state.users = responseCategories.data.webform.users || [];
            state.subInfo = this.getInnerSubInfo(elementsAllModal).reduce(
              (acc, i) => ({ ...acc, [i.webform_key]: i }),
              {}
            );
          }

          this.setState(state);

          if (data && data.error && data.error.length)
            data.errors.forEach((err) => message.error(err));

          return response.data.webform.elements;
        }
      )
      .catch((e) => console.error("error ", e));
  };

  getInnerSubInfo = (items) =>
    items.reduce((acc, i) => {
      let newAcc = [
        ...acc,
        { ...i.sub_elements_count, webform_key: i.webform_key },
      ];

      if (i.elements.length)
        newAcc = [...newAcc, ...this.getInnerSubInfo(i.elements)];

      return newAcc;
    }, []);

  getInnerElements = (items) =>
    items.reduce((acc, i) => {
      if (i.elements.length)
        return [...acc, ...this.getInnerElements(i.elements)];

      return [...acc, i];
    }, []);

  componentDidMount() {
    this.setState({fetch: true});

    api.questionnaires
      .getProgressForm(this.props.match.params.id)
      .then(({ data }) => {
        this.setState({
          mainItem: data,
          formProgress: data.progress,
        });
      })
      .then(() => this.fetchData(null, true))
      .then((data) => this.setState({elements: data}))
      .then(() => api.reports.getReportsByWebformId(this.props.match.params.id))
      .then(({ data }) => this.setState({reports: data.reports}))
      .catch((error) => {})
      .finally(() => this.setState({fetch: false}));
  }

  handleItemBlur = (key, isRequired, value) => {
    if (!value && isRequired) {
      this.setState({
        errors: {
          ...this.state.errors,
          [key]: "Veld is verplicht!",
        },
      });
    } 
    else {
      this.setState({
        errors: { ...this.state.errors, [key]: null },
      });
    }
  };

  debounceDefault = _.debounce((...props) => {
    this.setState({wasChanges: true});
    this.handleChange(...props);
  }, 500);

  debounceQuick = _.debounce((...props) => {
    this.setState({wasChanges: true});
    this.handleChange(...props);
  }, 400);

  handleChange = (
    key,
    parentKey,
    value,
    isRequired = false,
    organisationID = this.props.user.data.organisation,
    multiset = false
  ) => {
    if (isArray(value))
      value = value.toString();

    console.log(`Field ${key} changed, value:`);
    console.log(value);

    if (!value && isRequired) {
      this.setState({
        errors: {
          ...this.state.errors,
          [key]: "Veld is verplicht!",
        },
        data: {
          ...this.state.data,
          [key]: value,
        },
      });

      return;
    } 
    else
      this.setState({ errors: { ...this.state.errors, [key]: null } });

    if (organisationID === this.props.user.data.organisation) {
      this.setState((state) => ({
        data: {
          ...this.state.data,
          [key]: value,
        },
        isSubmitting: true,
        valueOrganisation: {
          ...this.state.valueOrganisation,
          [organisationID + "," + key]: value,
        },
      }));
    } 
    else {
      this.setState((state) => ({
        valueOrganisation: {
          ...this.state.valueOrganisation,
          [organisationID + "," + key]: value,
        },
        activeOrganisation: this.state.activeOrganisation
          ? [...this.state.activeOrganisation, organisationID]
          : [organisationID],
      }));
    }
  };

  checkValidation = ({ data }) => {
    this.setState({
      errors: data.errors || {},
      warnings: data.warnings || {},
    });

    if (data.errors) {
      Object.keys(data.errors).forEach((key) => {
        console.error(data.errors[key]);
      });
    }

    if (data.warnings) {
      Object.keys(data.warnings).forEach((key) => {
        console.warn(data.warnings[key]);
      });
    }

    return { data };
  };

  submitBeforeGo = (activeItemModal, modalKey, modalLink) => {
    const { data: stateData, elements } = this.state;

    const key = activeItemModal;

    const initialData = Object.keys(stateData).map((i) => ({
      key: i,
      ...((isObject(stateData[i]) &&
        "calculated" in stateData[i] &&
        stateData[i].set && { value: stateData[i].set }) ||
        (!isObject(stateData[i]) && stateData[i] && { value: stateData[i] })),
    }));
    const webformId = this.props.match.params.id;

    this.setState({
      isSubmitting: true,
      errors: {},
    });

    return api.questionnaires
      .submitForm(webformId, {
        data: initialData,
        key,
        validate: true,
        submission_id: this.state.submissionId[this.props.user.organisation],
        period_start: this.state.periodStart,
      })
      .then(this.checkValidation)
      .then(() => {
        this.setState((state) => ({
          isSubmitting: false,
          elements: this.deepSearch(elements, key, {
            spinner: false,
          }),
        }));
      })
      .catch((err) => console.log(err));
  };

  checkUpdate = ({ webform_children: children }) => {
    const { initialData, data: currentData } = this.state;

    return children.reduce((acc, item) => {
      if (item.type === "category_container")
        return acc || this.checkUpdate(item);

      return (
        acc ||
        String(currentData[item.webform_key]) !==
          String(initialData[item.webform_key])
      );
    }, false);
  };

  openModal = (data) => {
    const state = {
      activeCategoryData: data,
      activeKey: null,
      isVisibleDetails: false,
      selectedField: {},
    };

    const { item } = data;
    const { isCompleted, formKeys, visibleContentDropdownKey } = this.state;

    if (visibleContentDropdownKey)
      return;

    if (
      !isCompleted &&
      formKeys.includes(item.webform_key) &&
      this.checkUpdate(item)
    )
      state.showModal = true;
    else
      this.handleAccordion(data);

    this.setState(state);
  };

  handleOk = () => {
    this.handleAccordion(this.state.activeCategoryData);

    this.setState({
      showModal: false,
      isVisibleDetails: false,
    });
  };

  handleCancel = () => {
    this.setState({
      showModal: false,
      activeCategoryData: null,
    });
  };

  handleComplete = (redirect = false) => {
    this.setState({isCompleting: true});

    const { data, isCompleted, redirectUrl, confirmationMessage } = this.state;

    const body = {};

    if (isCompleted) {
      body.reopen = true;
      body.submission_id = this.state.submissionId[this.props.user.organisation];
      body.period_start = this.state.periodStart;
    } 
    else {
      body.data = Object.keys(data).map((i) => ({
        key: i,
        ...((isObject(data[i]) &&
          "calculated" in data[i] &&
          data[i].set && { value: data[i].set }) ||
          (!isObject(data[i]) && data[i] && { value: data[i] })),
      }));
      body.finish = true;
      body.validate = true;
      body.submission_id = this.state.submissionId[this.props.user.organisation];
      body.period_start = this.state.periodStart;
    }

    return api.questionnaires
      .submitForm(this.props.match.params.id, body)
      .then(({ data }) => {
        this.setState({
          errors: {},
          warnings: {},
        });

        if (data.errors) {
          this.setState({errors: data.errors});
          Object.keys(data.errors).forEach((key) => {
            console.error(data.errors[key]);
          });

          message.error(this.props.t("error_save"));
        }

        if (data.warnings) {
          this.setState({warnings: data.warnings});
          Object.keys(data.warnings).forEach((key) => {
            console.warn(data.warnings[key]);
          });
          if (data.warnings.form)
            data.warnings.form.forEach((i) => message.error(i));
        }

        this.setState((prevState) => ({
          isCompleting: false,
          isCompleted: !data.errors
            ? !prevState.isCompleted
            : prevState.isCompleted,
        }));

        if (redirect && redirectUrl && !data.errors) {
          message.success(`${confirmationMessage}`);
          setTimeout(() => {
            window.location = redirectUrl;
          }, 500);
        }
      });
  };

  buildStructure = (item) => {
    if (item.type === "category_container") {
      return {
        ...item,
        webform_children: item.elements.map(this.buildStructure),
      };
    }

    return item;
  };

  filterCategories = () => {
    const categoryIds = this.state.elementsAllModal.map((i) => i.webform_key);

    if (
      this.state.formKeys.filter((i) => categoryIds.includes(i)).length ===
      categoryIds.length
    ) {
      this.setState({
        formKeys: [],
      });
    } 
    else {
      this.setState({
        formKeys: categoryIds,
      });
    }
  };

  toEdit = (remark) => {
    this.setState({remark});
  };

  handleChangeTags = (tag, checked, isDisabled = false) => {
    const { selectedTags } = this.state;
    let newTags;

    if (!checked && selectedTags.length <= 1)
      return;

    if (checked)
      newTags = [...selectedTags, tag];
    else
      newTags = selectedTags.filter((i) => i !== tag);

    this.setState({selectedTags: newTags});
  };

  onShoweSidebar = () => {
    this.setState({
      visibleSideBar: !this.state.visibleSideBar,
    });
  };

  mapCategory = (i, key) => {
    const userTags = this.props.app.tags
      .filter((i) => this.props.user.data.tags.includes(i.id))
      .map((i) => i.id);

    return (
      <div className="containerListLink" key={key}>
        <div className="listLink">
          {i.elements.length ? (
            <>
              <div className="titleLink">{i.title}</div>
              {i.elements.map((i, key) => this.mapCategory(i, ++key))}
            </>
          ) : (
            <>
              {userTags.length ? (
                <>
                  {!!i.webform_tags.filter((i) => userTags.includes(i))
                    .length &&
                    i.is_visible && (
                      <Link
                        to="#"
                        key={key}
                        onClick={async () => {
                          await this.handleSubmit(
                            this.state.activeItemModal,
                            this.state.modalElements
                          ).then(() => {
                            this.onVisibleModal(i.webform_key, true);
                          });
                        }}
                        className={
                          i.webform_key === this.state.activeItemModal
                            ? "activeLink"
                            : "notActiveLink"
                        }
                      >
                        {i.title}
                      </Link>
                    )}
                </>
              ) : (
                i.is_visible && (
                  <Link
                    to="#"
                    key={key}
                    onClick={async () => {
                      await this.handleSubmit(
                        this.state.activeItemModal,
                        this.state.modalElements
                      ).then(() => {
                        this.onVisibleModal(i.webform_key, true);
                      });
                    }}
                    className={
                      i.webform_key === this.state.activeItemModal
                        ? "activeLink"
                        : "notActiveLink"
                    }
                  >
                    {i.title}
                  </Link>
                )
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  handlePrevSection = async () => {
    const { activeItemModal, lineModalElements, modalElements } = this.state;

    const filterArr = lineModalElements.filter(
      (item) => item.is_visible === true
    );

    const index = filterArr.findIndex((i) => i.webform_key === activeItemModal);

    if (!index)
      return;

    await this.handleSubmit(activeItemModal, modalElements).then(() => {
      this.showDetails();
      this.onVisibleModal(filterArr[index - 1].webform_key, true);
    });
  };

  handleNextSection = async () => {
    const { activeItemModal, lineModalElements, modalElements } = this.state;

    const filterArr = lineModalElements.filter(
      (item) => item.is_visible === true
    );

    const index = filterArr.findIndex((i) => i.webform_key === activeItemModal);

    if (index + 1 === filterArr.length)
      return;

    await this.handleSubmit(activeItemModal, modalElements).then(() => {
      this.showDetails();
      this.onVisibleModal(filterArr[index + 1].webform_key, true);
    });
  };

  handleSubmit = async (
    key,
    item,
    needToClose = false,
    stateData = this.state.data,
    organisation = null
  ) => {
    console.log(`handleSubmit: ${key}, ${organisation}`);

    const { elements, isBasicSubmitted, lineModalElements } = this.state;

    const webformId = this.props.match.params.id;

    this.setState({
      fetchModal: true,
      isSubmitting: true,
      errors: {},
    });

    let data = [];

    for (let key in stateData) {
      const obj = {
        key,
        value: isObject(stateData[key])
          ? stateData[key]?.set || stateData[key]?.calculated
          : stateData[key],
      };

      const isReadOnly = this.state.modalElements?.find(
        (item) => item?.webform_key === key
      )?.readonly;

      if (!isReadOnly)
        data.push(obj);
    }

    if (organisation)
      data = stateData;

    var has_access = true;

    return api.questionnaires
      .submitForm(webformId, {
        data,
        key,
        validate: true,
        submission_id: organisation
          ? this.state.submissionId[organisation]
          : this.state.submissionId[this.props.user.data.organisation],
        period_start: this.state.periodStart,
        organisation_id: organisation,
      })
      .then(this.checkValidation)
      .then(({data}) => {
        if (data.errors) {
          if (data.errors.length == 1 && 0 in data.errors && data.errors[0].includes("allowed")) {
            has_access = false;
            message.error(this.props.t("webform_save_access_error"));
          }
        }
      })
      .then(() =>
        api.questionnaires.getSubmission(
          webformId,
          key,
          null,
          this.state.periodStart
        )
      )
      .then(async ({ data: { progress, data } }) => {
        const { basic, elements: elementsProgress = [], form } = progress;
        const initialData = data
          .filter((i) => i.value !== null)
          .reduce((acc, i) => ({ ...acc, [i.key]: i.value }), { ...stateData });

        const formattedData = Object.keys(initialData).reduce((acc, i) => {
          const isObject = initialData[i] && this.IsJsonString(initialData[i]);

          if (isObject) {
            // const activeObjectValue = JSON.parse(initialData[i]).set ? JSON.parse(initialData[i]).set : JSON.parse(initialData[i]).calculated
            return { ...acc, [i]: JSON.parse(initialData[i]) };
          } 
          else
            return { ...acc, [i]: initialData[i] };
        }, {});

        const responseData = await this.fetchData(this.state.parentKey, false);
        this.setState({ modalElements: responseData });

        const stateProgress = elementsProgress.reduce(
          (acc, el) => ({ ...acc, [el.key]: el }),
          {}
        );

        this.setState((state) => ({
          isSubmitting: false,
          progress: stateProgress,
          elements: this.deepSearch(elements, key, {
            spinner: false,
          }),
          data: { ...formattedData },
          initialData,
          formProgress: form,
        }));

        if (
          !isBasicSubmitted &&
          basic.count_required === basic.filled_required
        ) {
          return api.questionnaires
            .getAllElements(webformId)
            .then(({ data: { webform } }) => {
              this.setState({
                elements: webform.elements.map(this.buildStructure),
                isBasicSubmitted: true,
              });
            });
        }
      })
      .then(() =>
        api.questionnaires.getProgressForm(this.props.match.params.id)
      )
      .then(({ data }) => {
        this.setState({
          mainItem: data,
          formProgress: data.progress,
        });
      })
      .then(() => {
        if (has_access)
          message.success(this.props.t("save_success"));

        if (needToClose) {
          this.setState({
            visibleModal: !this.state.visibleModal,
            isVisibleDetails: false,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.fetchData(lineModalElements[0].webform_parent_key, true);
        this.setState({ wasChanges: false, fetchModal: false });
        const activeOrg = this.state.activeOrganisation;
        if (activeOrg?.length) {
          const sortArr = activeOrg?.forEach((v) => {
            const res = [];
            const valueOrganisation = this.state.valueOrganisation;
            for (let k in valueOrganisation) {
              const [org, keyItem] = k.split(",");
              res.push({
                key: keyItem,
                value: valueOrganisation[v + "," + keyItem],
              });
            }

            this.handleSubmit(key, item, false, res, v);
          });
        }

        this.setState({ activeOrganisation: null });
      });
  };

  getActivePeriods = async (period) => {
    console.log(`getActivePeriods: ${period}`);
    this.setState({showLoader: true});
    await this.fetchData(
      this.state.parentKey,
      true,
      this.props.user.data.organisation,
      period
    );
    this.setState({
      periodStart: period,
      showLoader: false,
    });
  };

  render() {
    const {
      // elements,
      options,
      fetch,
      activeCategoryData,
      showModal,
      showHistoryModal,
      isCompleting,
      isCompleted,
      canReopen,
      isVisibleDetails,
      selectedField,
      remarks,
      remark,
      fetchDetails,
      isBasicSubmitted,
      // reports,
      historyValues,
      historyFetching,
      selectedTags,
      elementsAllModal,
      modalElements,
      mainItem,
      isSubmitting,
      activeItemModal,
      fetchModal,
      progress,
      lineModalElements,
      formProgress,
      formKeys,
      questionnaireOrganization,
      userInfo,
      updateForm,
    } = this.state;

    const isInactiveData = options.data_entry !== "active";

    const {
      app: { settings },
    } = this.props;

    const {
      t,
      user: {
        data: {
          data: { display_name: userName },
          permissions,
          organisation,
          organisations,
        },
      },
      app: { tags },
    } = this.props;

    const userTags = this.props.app.tags
      .filter((i) => this.props.user.data.tags.includes(i.id))
      .map((i) => i.id);

    const filteredLineModalElementsByUserTags = lineModalElements.reduce(
      (acc, i) => {
        if (
          i.is_visible &&
          i.webform_tags.filter((tag) => userTags.includes(tag)).length
        )
          return [...acc, i];
        else
          return acc;
      },
      []
    );

    const index = filteredLineModalElementsByUserTags.length
      ? filteredLineModalElementsByUserTags.findIndex(
          (i) => i.webform_key === activeItemModal
        )
      : lineModalElements.findIndex((i) => i.webform_key === activeItemModal);

    const setNext = (index) => {
      for (let i = index; i < lineModalElements.length; i++) {
        if (lineModalElements[i].is_visible)
          return lineModalElements[i];
      }
    };

    const setPrev = (index) => {
      for (let i = index; i > 0; i--) {
        if (lineModalElements[i].is_visible)
          return lineModalElements[i];
      }
    };

    const prevItem =
      !!index &&
      (filteredLineModalElementsByUserTags.length
        ? filteredLineModalElementsByUserTags[index - 1]
        : setPrev(index - 1));

    const nextItem =
      index + 1 !== lineModalElements.length &&
      (filteredLineModalElementsByUserTags.length
        ? filteredLineModalElementsByUserTags[index + 1]
        : setNext(index + 1));

    const isFilledRequired =
      formProgress &&
      formProgress.filled_required === formProgress.count_required;

    return (
      <Fragment>
        <div
          className={classNames({
            "qs-container": true,
            "showing-details": isVisibleDetails,
          })}
        >
          <Modal
            title={activeCategoryData && activeCategoryData.item.title}
            open={showModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            {t("changes_will_not_be_saved")}
          </Modal>

          {showHistoryModal && (
            <Modal
              visible
              onCancel={this.closeHistoryModal}
              footer={null}
              width={900}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <DatePicker.RangePicker
                  format="YYYY/MM/DD"
                  onChange={this.handleDatePickerChange}
                />
              </div>
              <Table
                rowKey="id"
                columns={historyColumns}
                dataSource={historyValues}
                loading={historyFetching}
              />
            </Modal>
          )}
          <div
            className="container mt-3"
            style={{ maxWidth: "100%", paddingLeft: 0 }}
          >
            {fetch ? (
              <div className="d-flex justify-content-center mt-5">
                <Spin />
              </div>
            ) : (
              <Fragment>
                <div className="titleQuestionnaire">
                  <div className="titleQuestionnaire_left">
                    <span className="titel">{mainItem && mainItem.title}</span>
                    <div
                      style={{
                        marginTop: 13,
                        color: "#333333",
                        display: "flex",
                      }}
                    >
                      {/* <span style={{ marginRight: 30, display: 'flex', alignItems: 'center' }}><Icon type="clock-circle" style={{ marginRight: 10 }} />Due on 12-05-2020</span> */}
                      <span style={{ marginRight: 30 }}>
                        <img
                          src={iconChatText}
                          alt=""
                          style={{ marginRight: 10 }}
                        />
                        {mainItem.progress ? mainItem.progress.count_input : 0}{" "}
                        {mainItem.progress &&
                        mainItem.progress.count_input === 1
                          ? t("general:question")
                          : t("general:questions")}{" "}
                        (
                        {mainItem.progress
                          ? mainItem.progress.count_required
                          : 0}
                        <span style={{ color: "red" }}>*</span>)
                      </span>
                      <Progress
                        status="active"
                        percent={mainItem.progress ? +parseFloat((100 / mainItem.progress.count_input) * mainItem.progress.filled).toFixed(1) : 0}
                        size="small"
                        style={{ width: 150, marginRight: 30 }}
                        showInfo/>
                    </div>
                  </div>
                  <div className="titleQuestionnaire_right">
                    <div>
                      {isCompleted ? (
                        <Popconfirm
                          title={<div dangerouslySetInnerHTML={{__html: t("be_careful")}}/>}
                          onConfirm={() => this.handleComplete(false)}
                          disabled={!canReopen || !isBasicSubmitted || isInactiveData}
                          okText={t("general:yes")}
                          cancelText={t("general:no")}>
                          <Button
                            type="primary"
                            style={{
                              width: 195,
                              height: 35,
                              borderRadius: 21,
                              backgroundColor: "#134176",
                            }}
                          >
                            {t("reopen")}
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Button onClick={this.startFilling} style={{width: 195, height: 35, borderRadius: 21, backgroundColor: "#134176"}} type="primary">
                          {t("start_filling")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                {!!this.props.user.data.tags.length && (
                  <>
                    <div
                      className="Line"
                      style={{
                        background: "#ddd",
                        height: 1,
                        width: "100%",
                        marginBottom: 30,
                      }}
                    />

                    <div className="containerFillter">
                      <div>
                        <span
                          style={{
                            marginRight: 19,
                            fontSize: 16,
                            color: "black",
                          }}
                        >
                          {t("filter_by_tag")}
                        </span>
                        {tags
                          .filter((tag) => this.props.user.data.tags.includes(tag.id))
                          .map((tag) => (
                            <Tag.CheckableTag
                              key={tag.id}
                              checked={selectedTags.includes(tag.id)}
                              onChange={(checked) =>
                                this.handleChangeTags(tag.id, checked)
                              }
                            >
                              {tag.name}
                            </Tag.CheckableTag>
                          ))}
                      </div>
                      <div
                        className="qs-filter"
                        onClick={this.filterCategories}
                      >
                        <span style={{ color: "#21416f" }}>
                          {elementsAllModal.length === formKeys.length
                            ? t("collapse_all")
                            : t("expand_all")}
                        </span>
                        {elementsAllModal.length !== formKeys.length ? (
                          <PlusSquareOutlined
                            style={{ fontSize: 20, fontWeight: 900 }}
                          />
                        ) : (
                          <MinusSquareOutlined
                            style={{ fontSize: 20, fontWeight: 900 }}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                {elementsAllModal
                  .filter((el) => el.type === "category_container")
                  .map((item) => {
                    const element = this.mapElement(item, item.basic_category);
                    return (
                      element && (
                        <div className="qs-item" key={item.webform_key}>
                          {element}
                        </div>
                      )
                    );
                  })}
              </Fragment>
            )}
          </div>

          <div className="qs-details">
            <div className="iconClose" onClick={() => this.showDetails()}>
              <CloseOutlined style={{ fontSize: 15 }} />
            </div>
            {fetchDetails ? (
              <div className="qs-loading-overlay">
                <Spin />
              </div>
            ) : (
              <div className="contanerDetails">
                {selectedField.description && (
                  <Fragment>
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: selectedField.description || "",
                      }}
                    />
                  </Fragment>
                )}
                {options.show_remarks && (
                  <div className="qs-details-comment">
                    {/* <div className='qs-details-label'>{t('comment')}</div> */}

                    {remarks.map((item) => (
                      <Comment
                        key={item.created_at}
                        author={<a>{item.user}</a>}
                        content={
                          <div
                            dangerouslySetInnerHTML={{ __html: item.text }}
                          />
                        }
                        datetime={
                          <Tooltip
                            title={moment(item.updated_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          >
                            <span>{moment(item.updated_at).fromNow()}</span>
                          </Tooltip>
                        }
                        actions={
                          item.user === userName
                            ? [
                                <span onClick={() => this.toEdit(item)}>
                                  {t("edit_remark")}
                                </span>,
                              ]
                            : []
                        }
                      />
                    ))}

                    <TextArea
                      className="detail_textArea"
                      value={remark.text}
                      // placeholder='Write a comment'
                      onChange={this.handleEditorChange}
                    />

                    <div className="containerButton">
                      {remark.remark_id ? (
                        <Button
                          type="danger"
                          onClick={() => this.toEdit({ text: "" })}
                          className="cansel"
                        >
                          Cancel
                        </Button>
                      ) : (
                        <div />
                      )}
                      <Button type="default" onClick={this.saveRemark}>
                        {remark.remark_id ? "Update" : t("add_remark")}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="containerHelpCenter" style={{ opacity: 0 }}>
          <div className="containerHelpCenter_text">
            <span style={{ color: "#000" }}>
              Any questions? Visit our Help Center.
            </span>
            <Button
              type="default"
              style={{
                borderRadius: "18px",
                fontSize: "14px !important",
                width: "146px",
                height: "35px",
              }}
            >
              Go to Help center
            </Button>
          </div>
          <img src={helpWheel} alt="" />
        </div>

        {this.state.visibleModal && (
          <div className="containerModalTest">
            <div className="modalHeader">
              <div className="logo-box">
                <Link to="/" className="logo text-center">
                  <span className="logo-lg">
                    <img
                      style={{ display: "block" }}
                      src={settings.logo}
                      alt=""
                      height="70"
                    />
                  </span>
                </Link>
              </div>
              <div className="contentHeader">
                <Tooltip placement="left" title={mainItem.title}>
                  <div className="modalTitle">
                    {mainItem && mainItem.title.length <= 36
                      ? mainItem.title
                      : mainItem.title.slice(0, 36) + "..."}
                  </div>
                </Tooltip>
                <div className="containerButton">
                  <Fragment>
                    {isCompleted ? (
                      <Popconfirm
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t("be_careful"),
                            }}
                          />
                        }
                        onConfirm={() => this.handleComplete(false)}
                        disabled={
                          !canReopen || !isBasicSubmitted || isInactiveData
                        }
                        okText={t("general:yes")}
                        cancelText={t("general:no")}
                      >
                        <Button type="default" disabled={!canReopen || !isBasicSubmitted || isInactiveData}>
                          {t("reopen")}
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        type="default"
                        onClick={() => this.handleComplete(true)}
                        disabled={
                          !isFilledRequired ||
                          !isBasicSubmitted ||
                          isInactiveData
                        }
                      >
                        {t("submit")}
                      </Button>
                    )}
                  </Fragment>
                  <Button
                    type="default"
                    disabled={isCompleted || isInactiveData}
                    onClick={() =>
                      this.handleSubmit(activeItemModal, modalElements, true)
                    }
                  >
                    {t("save_close")}
                  </Button>
                  <Button
                    disabled={isCompleted || isInactiveData}
                    onClick={() =>
                      this.handleSubmit(activeItemModal, modalElements)
                    }
                    style={{
                      background: `${
                        this.state.wasChanges ? "greenyellow" : "transparent"
                      }`,
                    }}
                    type="default"
                  >
                    {t("save")}
                  </Button>
                  <Button
                    type="link"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "none",
                    }}
                    onClick={() => this.onVisibleModal(null, false, true)}
                  >
                    <CloseOutlined style={{ color: "#21416f", fontSize: 20 }} />
                  </Button>
                </div>
              </div>
              <div className="containerProgressbar">
                <Progress
                  percent={
                    progress[activeItemModal]
                      ? +parseFloat(
                          (100 / progress[activeItemModal].count) *
                            progress[activeItemModal].filled
                        ).toFixed(1)
                      : 0
                  }
                  status="active"
                  showInfo={false}
                  strokeWidth={5}
                />
              </div>
            </div>

            <div
              className="buttonBurger"
              onClick={this.onShoweSidebar}
              style={{
                display: this.state.visibleSideBar ? "flex" : "none",
                fontSize: 18,
                color: "#134176",
              }}
            >
              <RightOutlined />
            </div>

            <div
              className="containerSidebar"
              style={{ display: this.state.visibleSideBar ? "none" : "block" }}
            >
              <div className="backToQuestionnaires">
                <Link to="/questionnaires">
                  <LeftOutlined style={{ marginRight: 14 }} /> {t("home")}
                </Link>
              </div>

              <div className="showSidebar" onClick={this.onShoweSidebar}>
                <span>{t("index")}</span>
                <LeftOutlined style={{ fontSize: 18, color: "#134176" }} />
              </div>
              <div className="containerLists">
                {elementsAllModal.map((i, key) => this.mapCategory(i, key))}
              </div>
            </div>

            <div
              className="containerContent"
              style={{
                width: this.state.visibleSideBar
                  ? "100vw"
                  : "calc(100vw - 215px)",
                left: this.state.visibleSideBar ? 0 : 215,
              }}
            >
              {!!this.props.user.data.tags.length && (
                <div className="containerFillter">
                  <div className="leftContainer">
                    <span className="title">{t("filter_by_tag")}: </span>
                    {tags
                      .filter((tag) =>
                        this.props.user.data.tags.includes(tag.id)
                      )
                      .map((tag) => (
                        <Tag.CheckableTag
                          key={tag.id}
                          checked={selectedTags.includes(tag.id)}
                          onChange={(checked) =>
                            this.handleChangeTags(tag.id, checked)
                          }
                        >
                          {tag.name}
                        </Tag.CheckableTag>
                      ))}
                  </div>
                  {false && (
                    <div className="rightContainer">
                      <span
                        style={{
                          marginRight: 10,
                          color: "#000",
                          fontSize: 16,
                          lineHeight: 1.25,
                        }}
                      >
                        {t("filter_by_type")}
                      </span>
                      <Select defaultValue="All">
                        <Option value="All">All</Option>
                      </Select>
                    </div>
                  )}
                </div>
              )}
              {this.state.showLoader && <Loading />}
              {fetchModal ? (
                <div className="containerSpin">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <div className="containerMainQuestionnaire">
                    <div className="organization__info">
                      <p>
                        Organization info: <br />
                        {organisation
                          ? `ID: ${organisation} / Title: ${
                              organisations?.find(
                                (item) => item.id === organisation
                              )?.title
                            }`
                          : ""}
                      </p>
                      <SelectPeriods
                        id={this.props.match.params.id}
                        getBenchmarkPeriods={this.props.getSubmissionPeriods}
                        getActivePeriods={(period) =>
                          this.getActivePeriods(period)
                        }
                        selectedPeriod={this.state.periodStart}
                        allPeriods={this.state.allPeriods}
                      />
                    </div>

                    <div className="containerSwitch">
                      <div
                        className="switchItem"
                        onClick={this.handlePrevSection}
                        style={{ opacity: prevItem ? 1 : 0 }}
                      >
                        <LeftOutlined
                          style={{ marginRight: 11, color: "#134176" }}
                        />
                        <div className="switchItem_text">
                          <span className="title">{t("previous_section")}</span>
                          <span>{prevItem?.title}</span>
                        </div>
                      </div>

                      {nextItem && (
                        <div
                          className="switchItem"
                          onClick={this.handleNextSection}
                        >
                          <div
                            className="switchItem_text"
                            style={{ textAlign: "right" }}
                          >
                            <span className="title">{t("next_section")}</span>
                            <span>{nextItem?.title}</span>
                          </div>
                          <RightOutlined
                            style={{ marginLeft: 11, color: "#134176" }}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {modalElements.map((item, key) => {
                        const element = this.mapElementModal(
                          item,
                          item.basic_category
                        );
                        return (
                          element &&
                          (item.type === "webform_markup" ? (
                            element
                          ) : (
                            <Wrapper
                              options={options}
                              onClick={this.handleDetails}
                              permissions={permissions}
                              content={element}
                              mapElementModal={this.mapElementModal}
                              Tags={tags}
                              Item={item}
                              modalElements={modalElements}
                              fetchData={this.fetchData}
                              parentKey={this.state.parentKey}
                              user={this.props.user.data}
                              key={item.webform_key}
                              settings={settings}
                              userInfo={userInfo}
                              updateForm={updateForm}
                              wasChanges={this.state.wasChanges}
                              handleSubmit={this.handleSubmit}
                              activeItemModal={this.state.activeItemModal}
                            />
                          ))
                        );
                      })}
                    </div>
                    <div className="containerSwitch">
                      <div
                        className="switchItem"
                        onClick={this.handlePrevSection}
                        style={{ opacity: prevItem ? 1 : 0 }}
                      >
                        <LeftOutlined
                          style={{ marginRight: 11, color: "#134176" }}
                        />
                        <div className="switchItem_text">
                          <span className="title">{t("previous_section")}</span>
                          <span>{prevItem?.title}</span>
                        </div>
                      </div>

                      {nextItem && (
                        <div
                          className="switchItem"
                          onClick={this.handleNextSection}
                        >
                          <div
                            className="switchItem_text"
                            style={{ textAlign: "right" }}
                          >
                            <span className="title">{t("next_section")}</span>
                            <span>{nextItem.title}</span>
                          </div>
                          <RightOutlined
                            style={{ marginLeft: 11, color: "#134176" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withTranslation("questionnaire")(Questionnaire);
