import './ListInfo.scss';

import React from "react";
import { withTranslation } from 'react-i18next';

function ListInfo(props) {
  const { actionFunction, buttonText, id, title } = props;

  return (
    <div className={'listinfo-item'} onClick={() => actionFunction(id)}>
      <p className={'listinfo-title'}>{title}</p>
      <div className={'listinfo-actions'}>
        <button className={'listinfo-button'}>
          {buttonText}
        </button>
      </div>
    </div>
  );
}

export default withTranslation('benchmark')(ListInfo);