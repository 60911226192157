import CurrencyFormat from 'react-currency-format';
import { Input, InputNumber } from 'antd';
import React from 'react';

import './CustomInputNumber.scss'

export default function (props) {
  const { prefix = '', suffix = '', ...rest } = props;
  return (
    <div className='c-customInputNumber'>
      {prefix && <div className='c-customInputNumber__prefix'>{prefix}</div>}
      <InputNumber/>
      {suffix && <div className='c-customInputNumber__suffix'>{suffix}</div>}
    </div>
  );
}