import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-hot-loader/patch";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

import App from "./App";
import { AppContainer } from "react-hot-loader";
import { ConfigProvider } from "antd";
import { i18n } from "./services";
import { store } from "./store";

import "./assets/scss/DefaultTheme.scss";

const theme = {
  token: {
    borderRadius: 2, // Set the global border-radius for the antd components
  },
  components: {
    Input: {
      borderRadius: 2
    }
  }
};

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <ConfigProvider theme={theme}>
          <I18nextProvider i18n={i18n}>
            <Component />
          </I18nextProvider>
        </ConfigProvider>
      </Provider>
    </AppContainer>,
    document.getElementById("root")
  );
};

render(App);
