import actions from "../../store/actions";
import { connect } from 'react-redux';
import SkyBenchmark from "./SkyBenchmark";

const mapStateToProps = state => ({
  app: state.app,
  comparisonGroups: state.benchmark.comparisonGroups || [],
  forms: state.benchmark.benchmarkForms,
  isFetching: state.benchmark.isFetching,
  settingsBenchmark: state.benchmark.benchmarkSettings,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  deleteBenchmarkView: (params) => dispatch(actions.benchmark.deleteBenchmarkView(params)),
  getBenchmarkComparisonGroups: () => dispatch(actions.benchmark.getBenchmarkComparisonGroups()),
  getBenchmarkDataForCategory: (params) => dispatch(actions.benchmark.getBenchmarkDataForCategory(params)),
  getBenchmarkForms: () => dispatch(actions.benchmark.getBenchmarkForms()),
  getBenchmarkPeriods: (params) => dispatch(actions.benchmark.getBenchmarkPeriods(params)),
  getBenchmarkSettings: () => dispatch(actions.benchmark.getBenchmarkSettings()),
  getBenchmarkViewData: (params) => dispatch(actions.benchmark.getBenchmarkViewData(params)),
  getBenchmarkViewElements: (params) => dispatch(actions.benchmark.getBenchmarkViewElements(params)),
  getBenchmarkViews: (params) => dispatch(actions.benchmark.getBenchmarkViews(params)),
  getFilteredOrganizationForBenchmark: (form_id, activeFilters) => dispatch(actions.benchmark.getFilteredOrganizationForBenchmark(form_id, activeFilters)),
  searchBenchmarkData: (params) => dispatch(actions.benchmark.searchBenchmarkData(params)),
  searchBenchmarkElements: (params) => dispatch(actions.benchmark.searchBenchmarkElements(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkyBenchmark);