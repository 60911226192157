import actions from '../../../../store/actions';
import { connect } from 'react-redux';
import ModalBenchmarkChart from "./ModalBenchmarkChart";

const mapStateToProps = state => ({
  settingsBenchmark: state.benchmark.benchmarkSettings
});

const mapDispatchToProps = dispatch => ({
  getBenchmarkChartData: (params) => dispatch(actions.benchmark.getBenchmarkChartData(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBenchmarkChart);