import actions from '../../../../store/actions';
import { connect } from 'react-redux';
import ModalBenchmarkViewEditor from "./ModalBenchmarkViewEditor";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  createBenchmarkView: (params) => dispatch(actions.benchmark.createBenchmarkView(params)),
  getAllBenchmarkElements: (params) => dispatch(actions.benchmark.getAllBenchmarkElements(params)),
  saveBenchmarkView: (params) => dispatch(actions.benchmark.saveBenchmarkView(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalBenchmarkViewEditor);