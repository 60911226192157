import "./SkyBenchmark.scss";

import { api } from "../../services";
import BenchmarkMap from "../../assets/images/svg/benchmarkMap";
import BenchmarkTableEdit from "../../assets/images/svg/benchmarkTableEdit";
import { BranchesOutlined, CloseSquareFilled, DeleteFilled, DownloadOutlined, DownOutlined, EditFilled, EditOutlined, InfoCircleOutlined, PieChartOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons/lib/icons";
import { Button, Checkbox, Input, message, Modal, Popconfirm, Popover, Select, Spin, Tooltip } from "antd";
import history from "../../history";
import { 
  LevelFilter, 
  ListInfo, 
  ModalComparisonGroup,
  ModalDrilldownGroups, 
  ModalDrilldownOrganization, 
  NoRecords, 
  SearchInput, 
  SegmentationFilters 
} from "../../components";
import { ModalBenchmarkChart, ModalBenchmarkViewEditor } from "./Components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

const { Option } = Select;

function SkyBenchmark(props) {
  const { id } = useParams();
  const { 
    comparisonGroups, 
    forms,
    deleteBenchmarkView,
    getBenchmarkComparisonGroups,
    getBenchmarkDataForCategory,
    getBenchmarkForms, 
    getBenchmarkPeriods, 
    getBenchmarkSettings,
    getBenchmarkViewData,
    getBenchmarkViewElements,
    getBenchmarkViews,
    getFilteredOrganizationForBenchmark,
    searchBenchmarkData,
    searchBenchmarkElements,
    settingsBenchmark, 
    t, 
    user 
  } = props;

  const [access, setAccess] = useState(true);
  const [activeGroups, setActiveGroups] = useState([]);
  const [activeOrganizations, setActiveOrganizations] = useState([]);
  const [activeSearchValue, setActiveSearchValue] = useState(null);
  const [activeSegmentationFilters, setActiveSegmentationFilters] = useState({levelFilters: [], segmentationFilters: []});
  const [activeView, setActiveView] = useState(null);
  const [categories, setCategories] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [currentPeriod, setCurrentPeriod] = useState(null);
  const [currentSubcategory, setCurrentSubcategory] = useState(null);
  const [drilldownGroupData, setDrilldownGroupData] = useState([]);
  const [drilldownGroupElement, setDrilldownGroupElement] = useState(null);
  const [drilldownOrganizationData, setDrilldownOrganizationData] = useState([]);
  const [drilldownOrganizationElement, setDrilldownOrganizationElement] = useState(null);
  const [elements, setElements] = useState([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);
  const [gridColumns, setGridColumns] = useState([]); 
  const [gridData, setGridData] = useState([]);
  const [levelFilters, setLevelFilters] = useState(null);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingElements, setLoadingElements] = useState(false);
  const [loadingGridData, setLoadingGridData] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [loadingLevels, setLoadingLevels] = useState(false);
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [loadingPeriods, setLoadingPeriods] = useState(false);
  const [loadingSegmentationFilters, setLoadingSegmentationFilters] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [loadingSubcategories, setLoadingSubcategories] = useState(false);
  const [loadingViews, setLoadingViews] = useState(false);
  const [modalChartElement, setModalChartElement] = useState(null);
  const [modalChartVisible, setModalChartVisible] = useState(false);
  const [modalDrilldownGroupVisible, setModalDrilldownGroupVisible] = useState(false);
  const [modalDrilldownOrganizationVisible, setModalDrilldownOrganizationVisible] = useState(false);
  const [modalGroupEditorVisible, setModalGroupEditorVisible] = useState(false);
  const [modalViewEditorVisible, setModalViewEditorVisible] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsSearchValue, setOrganizationsSearchValue] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodType, setPeriodType] = useState('month');
  const [popoverGroupsVisible, setPopoverGroupsVisible] = useState(false);
  const [popoverOrganizationsVisible, setPopoverOrganizationsVisible] = useState(false);
  const [popoverViewsVisible, setPopoverViewsVisible] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [segmentationFilterData, setSegmentationFilterData] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [selectedView, setSelectedView] = useState(null);
  const [step, setStep] = useState(1);  // Step 1: Category selection, Step 2: Subcategory selection, Step 3: Benchmark
  const [subCategories, setSubCategories] = useState([]);
  const [views, setViews] = useState([]);

  // Loads the initial data like benchmark settings, comparison groups etc.
  // Only runs once after the initial render of the component by passing an empty dependency array
  useEffect(() => {
    const organization = user?.data?.organisations?.find((item) => item.id === user?.data?.organisation);
    setCurrentOrganization(organization);

    getBenchmarkForms();
    getBenchmarkComparisonGroups();
    loadBenchmarkSettings();

    async function fetchData() {
      setLoadingOrganizations(true);
      await getFilteredOrganizationForBenchmark(id, activeSegmentationFilters)
          .then((response) => {
            setOrganizations(response.data.values);
            setFilteredOrganizations(response.data.values);
          })
          .catch((e) => console.error(e))
          .finally(() => setLoadingOrganizations(false));
    }
    
    fetchData();
  }, []);

  // Loads the categories and organization filters
  // Runs when the selected webform (parameter ID) changes to ensure the correct categories and filters are shown
  useEffect(() => {
    if (!!!id)
      return;

    loadCategories();
    loadSegmentationFilters();
    loadLevels();
    loadViews();
  }, [id]);

  // Loads the available periods for the benchmark
  // Runs after the webform or user's organization changes to ensure only periods with existing data are shown for the user's organization
  useEffect(() => {
    if (!!!currentOrganization || !!!id)
      return;

    setLoadingPeriods(true);

    getBenchmarkPeriods({ data: id, is_full: 1, organisation: currentOrganization.id })
      .then(async (response) => {
        const { data, status } = await response;
        await data;

        if (data?.values?.data?.length && status === 200) {
          setPeriods(data.values.data);
          setCurrentPeriod(data.values.data[0].name);
          setPeriodStart(moment.parseZone(data.values.data[0].condition?.period_start).format('YYYY-MM-DD HH:mm:ss'));
          setPeriodEnd(moment.parseZone(data.values.data[0].condition?.period_end).format('YYYY-MM-DD HH:mm:ss'));
          setPeriodType(data.values.data[0].condition?.period_name);
        }
        else if (status === 200) {
          setAccess(false);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingPeriods(false));
  }, [id, currentOrganization]);

  // Loads/Updates the columns displayed in the benchmarking grid
  // Updates when changes are applied to the selected organizations/groups
  useEffect(() => {
    if (!!!currentOrganization)
      return;

    let newGridColumns = [];

    newGridColumns.push({
      id: currentOrganization?.id,
      name: currentOrganization?.title,
      title: currentOrganization?.title,
      tooltip: currentOrganization?.title,
      type: 'organization'
    });

    activeGroups.forEach(group => {
      newGridColumns.push({
        id: group.id,
        name: group.name,
        title: group.name,
        tooltip: group.name,
        type: 'group'
      });
    });

    activeOrganizations.forEach(org => {
      newGridColumns.push({
        id: org.id,
        name: org.title,
        title: org.title,
        tooltip: org.title,
        type: 'organization'
      });
    });

    if(!!settingsBenchmark?.extra_columns) {
      let extra_cols = settingsBenchmark?.extra_columns.map(ec => {
        return {
          id: ec,
          name: t(ec),
          title: t(ec),
          tooltip: `${t(ec)} ${t('extra_columns_definition')}`,
          type: 'aggregation'
        };
      });
      
      if (selectedColumns.length > 0)
        extra_cols = extra_cols.filter(c => selectedColumns.includes(c.id));

      newGridColumns = newGridColumns.concat(extra_cols);
    }

    setGridColumns(newGridColumns);
  }, [activeGroups, activeOrganizations, currentOrganization, selectedColumns]);

  useEffect(() => {
    // Prevent loading data when no view or category has been selected
    if (!!!activeSearchValue && !!!activeView && !!!currentCategory)
      return;
    
    // Prevent loading data when user first has to select a subcategory
    if (!!!activeSearchValue && !!!activeView && ((currentCategory.elements?.length ?? 0) !== 0 && !!!currentSubcategory))
      return;

    setLoadingElements(true);

    if (!!activeSearchValue) {
      searchBenchmarkElements({formId: id, searchValue: activeSearchValue})
        .then((response) => {
          if (response.status < 300)
            setElements(response.data?.values ?? []);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingElements(false));
    }
    else if (!!activeView) {
      getBenchmarkViewElements({formId: id, viewId: activeView.id})
        .then((response) => {
          if (response.status < 300)
            setElements(response.data?.values ?? []);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingElements(false));
    }
    else {
      api.questionnaires.getElements(id, (currentSubcategory || currentCategory).webform_key)
        .then((response) => {
          if (response.status < 300)
            setElements(response.data?.webform?.elements ?? []);
        })
        .catch((e) => console.error('error ', e))
        .finally(() => setLoadingElements(false));
    }    
  }, [activeView, currentCategory, currentSubcategory, activeSearchValue]);

  // Retrieves the grid data when the colums or active category have changed
  useEffect(() => {
    if (!!!currentCategory || !!!elements || !!!gridColumns || !!!id || !!!periodEnd || !!!periodStart)
      return;

    setLoadingGridData(true);
    setGridData([]);
    
    const data = {
      columns: gridColumns.map(c => { return {id: c.id, type: c.type}}),
      form_id: id,
      key: activeView === null ? (currentSubcategory || currentCategory).webform_key : activeView.id,
      period_start: periodStart,
      period_end: periodEnd,
      search_value: activeSearchValue
    };

    if (!!activeSearchValue) {
      searchBenchmarkData(data)
        .then((response) => {
          if (response.status < 300) {
            setGridData(response.data?.values?.elements ?? []);

            let tempDrilldownGroupElement = drilldownGroupElement;
            if (!!!tempDrilldownGroupElement) {          
              tempDrilldownGroupElement = elements.find(x => true);  
              setDrilldownGroupElement(tempDrilldownGroupElement);
            }

            setDrilldownGroupData(response.data?.values?.elements?.[tempDrilldownGroupElement.webform_key]?.['groups'] || []);
          }
        })
        .catch((e) => {
          console.error(e);
          setGridData([]);
        })
        .finally(() => setLoadingGridData(false));
    }
    else if (activeView === null) {
      getBenchmarkDataForCategory(data)
        .then((response) => {
          if (response.status < 300) {
            setGridData(response.data?.values?.elements ?? []);

            let tempDrilldownGroupElement = drilldownGroupElement;
            if (!!!tempDrilldownGroupElement) {
              tempDrilldownGroupElement = elements.find(x => true);
              setDrilldownGroupElement(tempDrilldownGroupElement);
            }

            setDrilldownGroupData(response.data?.values?.elements?.[tempDrilldownGroupElement.webform_key]?.['groups'] || []);
          }
        })
        .catch((error) => {
          console.error(error);
          setGridData([]);
        })
        .finally(() => setLoadingGridData(false));
    }
    else {
      getBenchmarkViewData(data)
        .then((response) => {
          if (response.status < 300) {
            setGridData(response.data?.values?.elements ?? []);

            let tempDrilldownGroupElement = drilldownGroupElement;
            if (!!!tempDrilldownGroupElement) {
              tempDrilldownGroupElement = elements.find(x => true);
              setDrilldownGroupElement(tempDrilldownGroupElement);
            }

            setDrilldownGroupData(response.data?.values?.elements?.[tempDrilldownGroupElement.webform_key]?.['groups'] || []);
          }
        })
        .catch((error) => {
          console.error(error);
          setGridData([]);
        })
        .finally(() => setLoadingGridData(false));
    }
  }, [elements, gridColumns, id, periodStart, periodEnd]);

  // Deletes a user's comparison group from the system
  const deleteGroup = (group) => {
    setLoadingGroups(true);

    api.benchmark.deleteComparisonGroup(group.id)
      .then((response) => {
        const { status } = response;
        if (status < 300)
          message.success(t("delete_comparison_group"));
      })
      .finally(() => {
        getBenchmarkComparisonGroups();
        setSelectedGroups(selectedGroups.filter((item) => item.id != group.id));
        setPopoverGroupsVisible(false);
        setLoadingGroups(false);
      });
  }

  // Retrieves the filtered and sorted organizations for selection
  const getFilteredOrganizations = () => {
    let orgs = filteredOrganizations
      .filter(x => x.id != currentOrganization.id) // Skip own organisation
      .sort((x, y) => x.title.localeCompare(y.title)); // Sort by title

    if(organizationsSearchValue)
      orgs = orgs?.filter((item) => item.title.search(new RegExp(organizationsSearchValue, 'i')) !== -1);

    return orgs;
  };

  // Opens the comparison group editor modal
  const handleAddGroupClick = () => {
    setModalGroupEditorVisible(true);
    setPopoverGroupsVisible(false);
  }

  const handleAddViewClick = () => {
    setModalViewEditorVisible(true);
    setPopoverViewsVisible(false);
  }

  // Applies the selected comparison groups
  const handleApplyGroupSelection = () => {
    setPopoverGroupsVisible(false);
    setActiveGroups(selectedGroups);

    message.success(`Filter ${t("apply")}!`);
  }

  // Applies the selected individual organizations
  const handleApplyOrganizationSelection = () => {
    setPopoverOrganizationsVisible(false);
    setActiveOrganizations(selectedOrganizations);

    message.success(`Filter ${t("apply")}!`);
  }

  // Loads the subcategories when a main category has been selected
  // If there are no subcategories available in the main category, it loads the benchmark for the main category
  const handleCategoryChange = (category) => {    
    if (category === undefined) category = null;

    setCurrentCategory(category);
    loadSubcategoriesOfCategory(category);
    if (category === null) {
      setStep(1);
      return;
    }

    if ((category.elements?.length ?? 0) === 0) {
      setStep(3);
    }
    else {
      setStep(2);
    }
  };

  const handleChartClick = (element) => {
    setModalChartElement(element);
    setModalChartVisible(true);
  }

  const handleCloseChartModal = () => {
    setModalChartVisible(false);
    setModalChartElement(null);
  }

  const handleCloseDrilldownGroupModal = () => {
    setModalDrilldownGroupVisible(false);
  }

  const handleCloseDrilldownOrganizationModal = () => {
    setModalDrilldownOrganizationVisible(false);
  }

  const handleCloseGroupEditor = () => {
    setModalGroupEditorVisible(false);
    setSelectedGroup(null);
  }

  const handleCloseViewEditor = () => {
    setModalViewEditorVisible(false);
    setSelectedView(null);
  }

  // Downloads the current benchmark view or category as xlsx
  const handleDownloadBenchmark = () => {
    if (loadingDownload)
      return;

    setLoadingDownload(true);

    const data = {
      columns: gridColumns.map(c => { return {id: c.id, type: c.type}}),
      form_id: id,
      format: 'xlsx',
      key: activeView === null ? (currentSubcategory || currentCategory).webform_key : activeView.id,
      period_start: periodStart,
      period_end: periodEnd,
      search_value: activeSearchValue
    };

    if (!!activeSearchValue) {
      searchBenchmarkData(data)
        .then((response) => {
          const { status, data } = response;
    
          if (status === 200)
            handleDownloadBenchmarkData(data);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingDownload(false));
    }
    else if (activeView === null) {
      getBenchmarkDataForCategory(data)
        .then((response) => {
          const { status, data } = response;
    
          if (status === 200)
            handleDownloadBenchmarkData(data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoadingDownload(false));
    }
    else {
      getBenchmarkViewData(data)
        .then((response) => {
          const { status, data } = response;
    
          if (status === 200)
            handleDownloadBenchmarkData(data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoadingDownload(false));
    }
  }

  const handleDownloadBenchmarkData = (data) => {
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${forms.find(f => f.id === id)?.title?.toLowerCase()?.replace(/ /ig, '_') ?? 'benchmark'}.xlsx`);   
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  // Opens the modal for the comparison group(s) drilldown
  const handleDrilldownGroupClick = (element) => {
    setDrilldownGroupElement(element);

    if (!loadingGridData)
      setDrilldownGroupData(gridData[element.webform_key]['groups']);
    
    setModalDrilldownGroupVisible(true);
  }

  // Opens the modal for the current organization drilldown
  const handleDrilldownOrganizationClick = (element) => {
    setDrilldownOrganizationElement(element);
    setDrilldownOrganizationData([]);
    setModalDrilldownOrganizationVisible(true);
  }

  // Opens the modal for the comparison group editor
  const handleEditGroupClick = (group) => {
    setModalGroupEditorVisible(true);
    setPopoverGroupsVisible(false);
    setSelectedGroup(group);
  }

  const handleGroupsChange = (ids) => {
    setSelectedGroups(comparisonGroups.filter((item) => ids.includes(item.id)));
  }

  async function handleLevelFilterChange(levelFilters) {
    setLoadingOrganizations(true);

    const updatedFilters = {
      ...activeSegmentationFilters,
      levelFilters: levelFilters
    };

    setActiveSegmentationFilters(updatedFilters);

    await getFilteredOrganizationForBenchmark(id, updatedFilters)
        .then((response) => {
          setFilteredOrganizations(response.data.values);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingOrganizations(false));
  }

  // const handleMapClick = (element) => {

  // }

  const handleOrganizationsChange = (ids) => {
    setSelectedOrganizations(organizations.filter((item) => ids.includes(item.id)));
  }

  const handlePeriodChange = (value) => {
    setCurrentPeriod(value);
    const currentPeriod = periods?.find((item) => item?.name === value);
    if (currentPeriod) {
      setPeriodStart(moment.parseZone(currentPeriod?.condition?.period_start).format('YYYY-MM-DD HH:mm:ss'));
      setPeriodEnd(moment.parseZone(currentPeriod?.condition?.period_end).format('YYYY-MM-DD HH:mm:ss'));
      setPeriodType(currentPeriod?.condition?.period_name);
    }

    setStep(1);
  };

  const handleResetBenchmarkView = () => {
    setActiveView(null);
    setSelectedGroups([]);
    setActiveGroups([]);
    setSelectedOrganizations([]);
    setActiveOrganizations([]);
  };

  const handleSearch = () => {
    if (!!!searchValue)
      setActiveSearchValue(null);
    else
      setActiveSearchValue(searchValue);
  };

  async function handleSegmentationFiltersChange(segmentationFilters) {
    setLoadingOrganizations(true);

    const updatedFilters = {
      ...activeSegmentationFilters,
      segmentationFilters: segmentationFilters
    };

    setActiveSegmentationFilters(updatedFilters);

    await getFilteredOrganizationForBenchmark(id, updatedFilters)
        .then((response) => {
          setFilteredOrganizations(response.data.values);
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingOrganizations(false));
  };

  const handleSelectView = (view) => {
    setActiveView(view);

    const groups = comparisonGroups.filter(x => view.filter_groups.includes(Number(x.id)));
    const orgs = organizations.filter(x => view.filter_organizations.includes(Number(x.id)));

    setSelectedGroups(groups);
    setActiveGroups(groups);
    setSelectedOrganizations(orgs);
    setActiveOrganizations(orgs);
    setPopoverViewsVisible(false);
  }

  const handleSubcategoryChange = (subCategory) => {
    if (subCategory === undefined) subCategory = null;
    setCurrentSubcategory(subCategory);
    setStep(subCategory === null ? 2 : 3);
  }

  const handleViewDelete = async (view) => {
    setLoadingViews(true);

    await deleteBenchmarkView({formId: id, viewId: view.id})
      .then((response) => {

      })
      .catch((e) => console.error(e))
      .finally(() => loadViews());
  }

  const handleViewEditClick = (event, view) => {
    event.stopPropagation();
    
    setSelectedView(view);
    setPopoverViewsVisible(false);
    setModalViewEditorVisible(true);
  }

  // Hides the view editor modal and reloads the list of user views after one has been saved
  const handleViewSaved = (view) => {
    setModalViewEditorVisible(false);
    loadViews();
  }

  // Loads the benchmark settings to set the statistic columns (AVG, MIN, MAX etc.)
  const loadBenchmarkSettings = () => {
    if (settingsBenchmark) {
      const optionListArray = settingsBenchmark?.extra_columns;

      setColumns(optionListArray);
      setSelectedColumns(optionListArray);
    }
    else {
      setLoadingSettings(true);
      getBenchmarkSettings()
        .then((response) => {
          const { data: { settings } } = response;
          const optionListArray = settings?.extra_columns;

          setColumns(optionListArray);
          setSelectedColumns(optionListArray);

          return response;
        })
        .catch((e) => console.error(e))
        .finally(() => setLoadingSettings(false));
    }
  }

  const loadCategories = () => {
    setLoadingCategories(true);

    api.questionnaires.getAllCollapseElements(id)
      .then(async (response) => {
        const { status, data } = response;
        await data;

        if (status === 200 && data.webform.elements) {
          setCategories(data.webform.elements);

          if (data.webform.elements.length === 1)
            handleCategoryChange(data.webform.elements[0]);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingCategories(false));
  }

  const loadLevels = () => {
    setLoadingLevels(true);

    api.benchmark.getBenchmarkLevels()
      .then((response) => {
        const { data: { terms } } = response;
        setLevelFilters(terms);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingLevels(false));
  }

  const loadSegmentationFilters = () => {
    setLoadingSegmentationFilters(true);

    api.questionnaires
      .getSegmentationElements(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200)
          setSegmentationFilterData(data.webform);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingSegmentationFilters(false));
  }

  const loadSubcategoriesOfCategory = (category) => {
    setLoadingSubcategories(true);
    setCurrentSubcategory(null);
    setSubCategories(category?.elements ?? []);
    setLoadingSubcategories(false);
  }; 

  // Loads the list of saved or shared views for the current user
  const loadViews = async () => {
    if (!!!user?.data?.data?.id) {
      console.warn('Cannot load benchmark views of user; the user ID cannot be found');
      return;
    }

    setLoadingViews(true);

    await getBenchmarkViews({formId: id, userId: user.data.data.id})
      .then((response) => {
        const { status, data } = response;
        if (status < 300)
          setViews(data?.values ?? []);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoadingViews(false));
  }

  // The content displayed in the column settings popover
  // Allows users to enable/disable aggregated columns like AVG, MIN and MAX
  const contentPopoverColumnSettings = (
    <div className={"benchmark-filter-popover-columns-content"}>
      <p className={"benchmark-filter-popover-title"}>
        {t('show_statistics')}
      </p>

      {loadingSettings ? (<Spin/>) : (
        <Checkbox.Group
          onChange={setSelectedColumns}
          options={columns.map(c => {
            return {
              label: t(c),
              value: c
            };
          })}
          value={selectedColumns}
        />
      )}
    </div>
  );

  // The content displayed in the comparison group selection popover
  // Includes checkboxes for selecting the groups and action buttons to add a new group, apply selection, delete a group and edit a group
  const contentPopoverGroups = (
    <div className={"benchmark-filter-popover-groups-content"}>
      <div className={'wrapper'}>

        <div className={'column'}>
          <p className={"title"}>{t("saved_comparison_groups")}</p>
          {loadingGroups ? (<Spin/>) : (
            <div className={"groups"}>
              <Checkbox.Group value={selectedGroups.map(g => g.id)} onChange={handleGroupsChange}>
                {comparisonGroups?.sort((x, y) => x.name.localeCompare(y.name))?.map((item) => (
                  <div className={"group"} key={item.id}>
                    <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>
                    <div className={"actions"}>
                      {item.personal_group && (
                        <>
                          <EditOutlined onClick={() => handleEditGroupClick(item)} />
                          <div className={"delete"} onClick={() => deleteGroup(item)} />
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </Checkbox.Group>
            </div>
          )}   
        </div>

        <div className={'column'}>
          <p className={'title'}>{t("create_comparison_group")}</p>
          {loadingGroups ? (<Spin/>) : (
            <>
              <button className={'action'} onClick={handleAddGroupClick}>
                {t("add_new_group")}
              </button>

              <button className={'action apply'} disabled={!!!elements || loadingGridData} onClick={handleApplyGroupSelection}>
                {t("apply")} {(!!!elements || loadingGridData) && (<span style={{marginLeft: '1em'}}><Spin size={'small'}/></span>)}
              </button>
            </>
          )}
        </div>

      </div>
    </div>
  );

  const contentPopoverOrganizations = (
    <div className={'benchmark-filter-popover-organizations-content'}>
      <div className={'organizations-content-wrapper'}>

        <div className={'organizations-content-column'}>
          <p className={"organizations-content-title"}>{t("individual_organizations_title")}</p>
          <p className={"organizations-content-subtitle"}>{t("individual_organizations_subtitle")}</p>
          <div className={"organizations-content-organizations"}>
            {loadingOrganizations ? <Spin size={'large'} style={{ padding: '20px' }} /> : (
              <Checkbox.Group onChange={handleOrganizationsChange} value={selectedOrganizations.map(o => o.id)}>
                {getFilteredOrganizations()?.map((item) => (
                  <Tooltip key={item.id} placement="left" title={item.title} zIndex={20}>
                    <Checkbox key={item.id} value={item.id}>{item.title}</Checkbox>
                  </Tooltip>
                ))}
              </Checkbox.Group>
            )}
          </div>
          <button className={"organizations-content-action"} onClick={handleApplyOrganizationSelection}>{t("apply")}</button>
        </div>

        <div className={'organizations-content-column'}>
          <p className={"organizations-content-title"}>{t("filters")}</p>
          <div className={'organizations-content-filters'}>
            <p>
              <SearchInput
                onSearch={setOrganizationsSearchValue}
                realtime={true}
                text={t("search_in_organizations")}
                value={organizationsSearchValue}
              />
            </p>

            <LevelFilter levels={levelFilters} onLevelFiltersChanged={handleLevelFilterChange} popoverId={'individual_organisations_popover'} />
            <SegmentationFilters id={id} onActiveFiltersChanged={handleSegmentationFiltersChange} popoverId={"individual_organisations_popover"} segmentationFilters={segmentationFilterData} />
          </div>
        </div>

      </div>
    </div>
  );

  // function SearchResults() {
  //   const search_categories = categories.filter(c => 
  //     !!elements.find(e => e.webform_parent_key == c.webform_key || e.webform_parent_parent_key == c.webform_key));
    
  //   return search_categories.map(sc => (
  //     <div className={'benchmark-search-category'}>
  //       <p className={'benchmark-search-category-title'}>{sc.title}</p>
  //     </div>
  //   ));
  // }

  const contentPopoverViews = (
    <div className={'benchmark-filter-popover-views-content'}>
      <div className={'views-content-wrapper'}>
        <div className={'views-content-header'}>
          <p className={"views-content-title"}>{t('favorites')}</p>
          <Button className={'views-content-new'} icon={<PlusCircleFilled/>} iconPosition={'end'} onClick={handleAddViewClick} type={'primary'}>
            {t('new')}
          </Button>
        </div>
        <div className={'views-content-list'}>
          {loadingViews || loadingGridData ? (<Spin/>) : (!!!views.length ? (
            <div className={'views-content-empty'}>
              <p>{t('no_views_available')}</p>
            </div>
          ) : views.map(v => (
            <div 
              className={`views-content-list-item ${!!activeView && activeView.id === v.id ? 'views-content-list-item-selected' : ''}`} key={v.id} 
              onClick={() => handleSelectView(v)}
            >
              <p className={'views-content-list-item-title'}>{v.title}</p>
              <div className={'views-content-list-item-action'}>
                <Button className={'views-content-edit'} icon={<EditFilled/>} onClick={(evt) => handleViewEditClick(evt, v)} type={'text'}/>

                <Popconfirm 
                  cancelText={t('cancel')}
                  description={t('delete_view_confirmation_desc')}
                  onConfirm={() => handleViewDelete(v)}
                  okText={t('confirm_delete')}
                  title={t('delete_view_confirmation_title')}
                >
                  <Button className={'views-content-delete'} icon={<DeleteFilled/>} type={'text'}/>
                </Popconfirm>
              </div>
            </div>
          )))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {access ? (
        <>
          <Modal
            footer={null}
            onCancel={handleCloseGroupEditor}
            open={modalGroupEditorVisible}
            style={{ maxWidth: "100vw", top: 0 }}
            title={selectedGroup === null ? t("create_new_group") : `${t("edit_group")} ${selectedGroup.name}`}
            width={"100%"}
          >
            <ModalComparisonGroup
              activeOrganization={currentOrganization}
              closeModal={handleCloseGroupEditor}
              comparisonGroupEditId={selectedGroup === null ? null : selectedGroup.id}
              indicatorFilterData={segmentationFilterData}
              visible={modalGroupEditorVisible}
            />
          </Modal>

          <Modal 
            className={"benchmark-chart-modal"}
            footer={null} 
            onCancel={handleCloseChartModal} 
            open={modalChartVisible} 
            title={modalChartElement?.title ?? t("data_chart")}
            width={"1200px"}
          >
            <ModalBenchmarkChart 
              columns={selectedColumns}
              element={modalChartElement}
              groups={activeGroups} 
              organization={currentOrganization}
              organizations={activeOrganizations} 
              periodEnd={periodEnd}
              periodStart={periodStart}
              periodType={periodType}
              value={gridData[modalChartElement?.webform_key]?.[currentOrganization.id]}
              visible={modalChartVisible}
            />
          </Modal>

          <Modal 
            footer={null} 
            onCancel={handleCloseViewEditor}
            open={modalViewEditorVisible} 
            style={{ maxWidth: "100vw", top: 0 }}
            title={selectedView === null ? t('create_new_view') : `${t('edit_view')} ${selectedView.title}`} 
            width={'100%'}
          >
            <ModalBenchmarkViewEditor 
              comparisonGroups={comparisonGroups} 
              formId={id} 
              loadingComparisonGroups={loadingGroups}
              onCancel={handleCloseViewEditor} 
              onSave={handleViewSaved} 
              organization={currentOrganization} 
              selectedView={selectedView}
            />
          </Modal>

          <Modal 
            className={'benchmark-drilldown-modal'} 
            footer={null} 
            onCancel={handleCloseDrilldownGroupModal} 
            open={modalDrilldownGroupVisible} 
            title={t('drilldown')} 
            width={'800px'}
          >
            <ModalDrilldownGroups data={drilldownGroupData} element={drilldownGroupElement} groups={activeGroups} loading={loadingGridData} />
          </Modal>

          <Modal 
            className={'benchmark-drilldown-modal'} 
            footer={null} 
            onCancel={handleCloseDrilldownOrganizationModal} 
            open={modalDrilldownOrganizationVisible} 
            title={t('drilldown')} 
            width={'800px'}
          >
            <ModalDrilldownOrganization data={drilldownOrganizationData} element={drilldownOrganizationElement} loading={loadingGridData} />
          </Modal>

          <div className={"benchmark-wrapper"}>
            <div className={"benchmark-header"}>
              <div className={"benchmark-header-items"}>
                
                <div className={'benchmark-header-select'}>
                  <p className="analyse-list__period-title">{t("period")}</p>
                  {loadingPeriods ? (<Spin/>) : (
                    <Select
                      className={'ant-select-borderless'}
                      onChange={(value) => handlePeriodChange(value)}
                      value={currentPeriod}
                    >
                      {periods?.map((item) => (
                        <Option key={item.name} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>

                <div className={'benchmark-header-select'}>
                  <p>{t("category")}</p>
                  {loadingCategories ? (<Spin/>) : (
                    <Select
                      className={'ant-select-borderless'}
                      onChange={(value) => handleCategoryChange(categories.find(c => c.webform_key === value))}
                      value={currentCategory === null ? null : currentCategory.webform_key}
                    >
                      <Option key={'null'} value={null}>{t("show_all")}</Option>
                      {categories?.map((item) => (
                        <Option value={item.webform_key} key={item.element_indicator}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>

                {subCategories.length === 0 ? null : (
                  <div className={'benchmark-header-select'}>
                    <p>{t("subcategory")}</p>
                    <Select
                      className={'ant-select-borderless'}
                      onChange={(value) => handleSubcategoryChange(subCategories.find(c => c.webform_key === value))}
                      value={currentSubcategory === null ? null : currentSubcategory.webform_key}
                    >
                      <Option key={'null'} value={null}>{t("show_all")}</Option>
                      {subCategories?.map((item) => (
                        <Option value={item.webform_key} key={item.element_indicator}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                
              </div>
              <div className={"benchmark-header-items"}>
                <div className={"benchmark-header-item"}>
                  <Input
                    className={"benchmark-searchbar"}
                    disabled={loadingElements || loadingGridData}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onPressEnter={handleSearch}
                    placeholder={t("search_in_questionnaire")}
                    suffix={
                      <SearchOutlined
                        onClick={handleSearch}
                        style={{ cursor: "pointer" }}
                      />
                    }
                    value={searchValue}
                  />
                </div>
              </div>
            </div>

            <div className={"benchmark-filters"}>
              <div className={'benchmark-filtergroup'}>
                {/* <span className={'benchmark-compare-text'}>{t("compare_to")}</span> */}

                <div className={'benchmark-filter'}>
                  <Popover
                    content={contentPopoverGroups}
                    onOpenChange={setPopoverGroupsVisible}
                    open={popoverGroupsVisible}
                    placement="bottomLeft"              
                    trigger={"click"}
                  >
                    <div className={'benchmark-filter-popover'}>
                      <p>
                        {(selectedGroups.length === 1 && selectedGroups[0].name) || t("comparison_groups")}
                      </p>
                      <DownOutlined />
                    </div>
                  </Popover>
                </div>

                <div className={'benchmark-filter'}>
                  <Popover
                    content={contentPopoverOrganizations}
                    id={"individual_organisations_popover"}
                    onOpenChange={setPopoverOrganizationsVisible}
                    open={popoverOrganizationsVisible}
                    placement="bottomLeft"
                    trigger={"click"}              
                  >
                    <div className={'benchmark-filter-popover'}>
                      <p style={{ maxWidth: "300px" }}>
                        {t("select_individual_organizations")}
                      </p>
                      <DownOutlined />
                    </div>
                  </Popover>
                </div>
              </div>

              <div className={'benchmark-filtergroup'}>
                <div className={'benchmark-filter-reverse'}>
                  <Popover 
                    content={contentPopoverViews} 
                    id={"views_popover"} 
                    onOpenChange={setPopoverViewsVisible} 
                    open={popoverViewsVisible} 
                    placement="bottomRight" 
                    trigger={"click"} 
                  >
                    <div className={'benchmark-filter-popover'}>
                      <p style={{ maxWidth: "300px" }}>
                        {activeView?.title || t('favorites')}
                      </p>
                      <DownOutlined/>
                    </div>
                  </Popover>
                </div>

                {!!activeView && (
                  <div className={'benchmark-filter-reverse'}>
                    <Button 
                      className={'benchmark-favorites-reset'} 
                      icon={<CloseSquareFilled/>} 
                      onClick={handleResetBenchmarkView} 
                      title={t('reset_benchmark')} 
                      type={'text'}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={'benchmark-content'}>
              {step === 1 && (
                <div className={'benchmark-step'}>
                  {categories?.map((item) => (
                    <ListInfo
                      actionFunction={(id) => handleCategoryChange(categories.find(c => c.webform_key === id))}
                      buttonText={t('to_show')}
                      id={item.webform_key}
                      key={item.webform_key}
                      title={item.title}  
                    />
                  ))}
                </div>
              )}

              {step === 2 && (
                <div className={'benchmark-step'}>
                  {subCategories?.map((item) => (
                    <ListInfo
                      actionFunction={(id) => handleSubcategoryChange(subCategories.find(c => c.webform_key === id))}
                      buttonText={t('to_show')}
                      id={item.webform_key}
                      key={item.webform_key}
                      title={item.title}  
                    />
                  ))}
                </div>
              )}

              {step === 3 && (
                <div className={'benchmark-grid'}>
                  <div className={'benchmark-grid-header'}>
                    {gridColumns?.map((item, index) => (
                      <Tooltip key={item.name + index} overlayInnerStyle={{textAlign: "center"}} overlayStyle={{maxWidth: "350px"}} placement={"top"} title={item.tooltip}>
                        <div className={'benchmark-grid-header-columns'} key={item.name + index}>
                          {item.title}
                        </div>
                      </Tooltip>
                    ))}

                    <Popover content={contentPopoverColumnSettings} placement="bottomLeft" trigger={"click"}>
                      <div className={'benchmark-grid-header-options'} title={t('table_settings')}>
                        <BenchmarkTableEdit />
                      </div>
                    </Popover>
                  </div>

                  <div className={'benchmark-grid-content'}>
                    {loadingElements ? <Spin size="large"/> : (!!!elements && <NoRecords/>)}                    

                    {/* {(!!!loadingElements && !!activeSearchValue?.length) && (
                      <></>
                    )} */}

                    {!!!loadingElements && elements?.map((element) => {
                      if (element.type === 'webform_markup') {
                        return (
                          <div key={element.webform_key} style={{background: '#134176', borderRadius: '5px', height: '100%', margin: '1em 0', padding: '19px', width: '100%'}}>
                              <div dangerouslySetInnerHTML={{ __html: element.markup }} style={{color: 'white', fontSize: '14px', lineHeight: '18px'}}/>
                          </div>
                        );                      
                      }
                      else {
                        return (
                          <div className={'benchmark-grid-row'} key={element.webform_key}>

                            <div className={'benchmark-grid-row-header'}>

                              <div className={'benchmark-grid-row-header-title'}>
                                <span>{element.title}</span>
                              </div>

                              <div className={'benchmark-grid-row-header-actions'}>

                                {settingsBenchmark?.show_drilldown_in_own_hierarchy === "yes" && (
                                  <>
                                    {!!activeGroups.length && (
                                      <div 
                                        className={'benchmark-grid-row-header-chart'} 
                                        onClick={(evt) => {
                                          evt.stopPropagation(); 
                                          handleDrilldownGroupClick(element);
                                        }}
                                      >
                                        <BranchesOutlined/> {" " + t("drilldown")}
                                      </div>
                                    )} 

                                    {!!element.is_multilevel && !!(user?.data?.organisation_tree ?? []).filter(v => v.parent_organisation === user.data.organisation).length && (
                                      <div 
                                        className={'benchmark-grid-row-header-chart'} 
                                        onClick={(evt) => {
                                          evt.stopPropagation(); 
                                          handleDrilldownOrganizationClick(element);
                                        }}
                                      >
                                        <BranchesOutlined/> {" " + t("drilldown_organisation")}
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* <div 
                                  className={'benchmark-grid-row-header-map'} 
                                  onClick={(evt) => {
                                    evt.stopPropagation();
                                    handleMapClick(element);
                                  }}
                                >
                                  {settingsBenchmark?.maps?.show_maps_view === "yes" && (
                                    <>
                                      <BenchmarkMap className={'benchmark-grid-row-header-map-image'} /> {t("card")}
                                    </>
                                  )}
                                </div> */}

                                {(element.type === 'number' || element.type === 'checkbox' || element.type === 'radios' || element.type === 'webform_select_other') && (
                                  <div 
                                    className={'benchmark-grid-row-header-chart'} 
                                    onClick={(evt) => {
                                      evt.stopPropagation();
                                      handleChartClick(element);
                                    }}
                                  >
                                    <PieChartOutlined/>
                                    <span>{t("chart")}</span>
                                  </div>
                                )}

                                <div className={'benchmark-grid-row-header-info'}>
                                  {element.webform_indicator + " "}
                                  {(element.description?.length ?? 0) > 0 && (
                                    <Popover 
                                      content={(
                                        <div style={{maxWidth: '300px'}}>
                                          {element.description}
                                        </div>
                                      )}
                                      placement={'top'}
                                      title={t("description")}
                                    >
                                      <InfoCircleOutlined />
                                    </Popover>
                                  )}
                                </div>

                              </div>
                            </div>

                            <div className={'benchmark-grid-row-content'}>
                              {gridColumns?.map((col, index) => {
                                return (
                                  <div className={'benchmark-grid-row-column'} key={element.webform_key + index}>
                                    {gridData.length === 0 ? (<Spin size="small"/>) : (
                                      (element.type === 'checkbox' ? (
                                        (col.type === 'group' ? (
                                          <Checkbox checked={(gridData[element.webform_key]?.['groups']?.[col.id]?.['value'] ?? 0) === 1} disabled={true} />
                                        ) : (
                                          <Checkbox checked={(gridData[element.webform_key]?.[col.id] ?? 0) === 1} disabled={true} />
                                        )) 
                                      ) : (
                                        (col.type === 'group' ? (
                                          gridData[element.webform_key]?.['groups']?.[col.id]?.['value']
                                        ) : (
                                          gridData[element.webform_key]?.[col.id]
                                        )) 
                                      ))
                                    )}
                                  </div>
                                );
                              })}

                            </div>

                          </div>
                        );
                      }
                    })}
                  </div>

                  <div className={'benchmark-grid-footer'}>
                    {settingsBenchmark?.download_benchmark_data === 'yes' &&
                      <Button
                        className={'benchmark-grid-download'}
                        disabled={loadingDownload}
                        icon={<DownloadOutlined />}
                        onClick={handleDownloadBenchmark}
                        size={'large'}
                        type={'primary'}
                      >
                        {t('download')} {loadingDownload ? <Spin size="small"/> : null}
                      </Button>
                    }
                  </div>             
                </div>
              )}
            </div>

          </div>
        </>
      ) : (
        <p>{t('no_permissions')}</p>
      )}
    </>
  );
}

export default withTranslation("benchmark")(SkyBenchmark);
