import './Topbar.scss';

import { ArrowBack } from "../index";
import { DownOutlined, InfoCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Dropdown, Modal } from 'antd';
import history from '../../history'
import iconlogout from '../../assets/images/svg/logout.svg'
import { Link } from 'react-router-dom'
import { ModalOrganisationInfo } from "./components";
import profilePic from '../../assets/images/svg/people-man.svg'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'

function Topbar(props) {
  const {
    app,
    forms,
    getOrganisationInfo,
    location,
    t,
    updateActiveOrganisation,
    user
  } = props;

  const [infoOrganisationId, setInfoOrganisationId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const handleSwitch = async (id) => {
    return await Promise.resolve()
      .then(() => {
        if (id === user.data.organisation)
          return null;

        return updateActiveOrganisation({organisation: id});
      })
      .then(() => window.location.reload())
  }

  const topbarTitle = (pathname) => {
    const splitted = pathname.split('/');
    const location = `/${splitted?.[1]}${splitted?.length > 2 ? '/' : ''}`;
    const optionalId = splitted.length > 1 ? splitted?.[splitted.length - 1] : null;

    switch (location) {
      case '/questionnaires': return t('general:questionnaires');
      case '/dashboard': return t('general:reports');
      case '/file-manager': return t('general:file_manager');
      case '/file-manager/': return t('general:file_manager');
      case '/profile': return t('general:users');
      case '/profile/': return <ArrowBack title={t('user:back_to_user_list')} url={'/profile'}/>;
      case '/skybenchmark': return t('general:benchmark');
      case '/benchmark/':
        if (!!!optionalId || optionalId == location)
          return t('general:benchmark');
        
        return forms?.find(f => f.id == optionalId)?.title ?? t('general:benchmark');
      default: return t('general:home');
    };    
  };

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_BASE_URL + '/user/logout';
  }

  const handleLogin = () => {
    history.push('/login');
  }

  const handlerOpenModal = (e, id) => {
    e.preventDefault();
    setModalVisible(true);
    setInfoOrganisationId(id);
  }

  const items = user.data.organisations.sort((x, y) => x.title.localeCompare(y.title)).map(o => {
    return {
      key: o.id,
      label: (
        <div className={'menu__item'} onClick={(e) => handleSwitch(o.id)}>
          <p>{o.title}</p>
          <div className={'menu__info'} onClick={(e) => { e.stopPropagation(); handlerOpenModal(e, o.id); }}>
            <InfoCircleOutlined />
          </div>
        </div>
      )
    };
  });

  const activeOrganisation = user.data.organisations.find(i => i.id === user.data.organisation);

  const closeModal = () => setModalVisible(false);

  return (
    <Fragment>
      <Modal
        footer={null}
        onCancel={closeModal}
        open={modalVisible}
        title={t('modal_title')}
        width={600}
      >
        <ModalOrganisationInfo
          getOrganisationInfo={getOrganisationInfo}
          organisationId={infoOrganisationId}
          t={t}
        />
      </Modal>

      <div className='navbar-custom'>
        <ul className='list-unstyled topnav-menu float-right mb-0'>
          
          {!user.isGuest ? (
              <>
                <li>
                  <div className='nav-link'>
                    <Dropdown className={'topbar-dropdown'} menu={{ items, selectable: true }}>
                        <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                          <img src={profilePic} style={{marginRight: '9px'}} alt=''/>
                          {user.data.data?.display_name} {!!activeOrganisation && ('(' + activeOrganisation.title + ')')}
                          <DownOutlined/>
                        </a>
                    </Dropdown>
                  </div>
                </li>

                <li>
                  <a className='nav-link' onClick={handleLogout}>
                    <img src={iconlogout} style={{marginRight: '9px'}} alt=''/> {t('header:logout')}
                  </a>
                </li>
              </>
            ) :
            (
              <>
                <li>
                  <div className='nav-link'>
                    <div style={{lineHeight: '1', marginTop: '22px'}}>{t('general:organisations')}</div>
                    <strong style={{lineHeight: '1', display: 'block'}}>{app.settings.guestMode.organisation_name}</strong>
                  </div>
                </li>
                <li>
                  <a className='nav-link' onClick={handleLogin}>
                    <img src={profilePic} style={{marginRight: '9px'}} alt=''/> {t('header:login')}
                  </a>
                </li>
              </>
            )}
        </ul>

        <div className='logo-box'>
          <Link to='/' className='logo text-center'>
                    <span className='logo-lg'>
                    <img style={{display: 'block'}} src={app.settings.logo} alt='' height='70'/>
                    </span>
          </Link>
        </div>

        {!user.isGuest && (
          <>
            {location.pathname.slice(0, 16) !== '/questionnaires/'  
              ? <div className='topnav-menu-left'>
                    <span>
                    {
                      topbarTitle(location.pathname)
                    }
                    </span>

              </div>
              : <div className='topnav-menu-left_link'>
                <Link to='/questionnaires'>
                  <LeftOutlined style={{marginRight: 14}}/>{t('back_to_list')}
                </Link>
              </div>
            }
          </>
        )}
      </div>
    </Fragment>
  );
}

export default withTranslation('header')(Topbar)
